import { gql } from "@apollo/client";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useAddMyServiceStackMutation,
  useGetMyServiceStacksQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AddNewServiceComponent } from "./component";

export const AddNewServiceContainer = (props: {}) => {
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyServiceStacksQueryData,
    loading: getMyServiceStacksQueryLoading,
    error: getMyServiceStacksQueryError,
  } = useGetMyServiceStacksQuery({
    client: bgwService.getClient(),
  });

  const [addStackError, setAddStackError] = useState<string | null>(null);
  const resetAddStackError = () => {
    setAddStackError(null);
  };

  const [addStackId, setAdddStackId] = useState<string>("");
  const [addStackType, setAdddStackType] = useState<string>("ECS");

  const [
    addMyServiceStackMutation,
    {
      loading: addMyServiceStackMutationLoading,
      error: addMyServiceStackMutationError,
    },
  ] = useAddMyServiceStackMutation({
    client: bgwService.getClient(),
  });

  const navigate = useNavigate();
  const navigateToEditStack = (stackId: string) => {
    navigate(`/service_statuses/${stackId}`, { replace: true });
  };

  const addStack = () => {
    addMyServiceStackMutation({
      variables: {
        stackId: addStackId,
        type: addStackType,
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyServiceStacks(existingStacks = []) {
              const newStackRef = cache.writeFragment({
                data: data?.addMyServiceStack,
                fragment: gql`
                  fragment NewServiceStack on ServiceStack {
                    id
                    name
                    type
                  }
                `,
              });
              return [...existingStacks, newStackRef];
            },
          },
        });
      },
      refetchQueries: ["GetMyServiceStacks"],
      onCompleted: (data) => {
        if (data?.addMyServiceStack !== undefined) {
          navigateToEditStack(data!.addMyServiceStack!.id);
          setAdddStackId("");
        }
      },
      onError: (error) => {
        setAddStackError(`Failed to add ${addStackId}.`);
        console.error(error);
      },
    });
  };

  if (getMyServiceStacksQueryError) {
    console.error(getMyServiceStacksQueryError);
  }

  if (addMyServiceStackMutationError) {
    console.error(addMyServiceStackMutationError);
  }

  return (
    <AddNewServiceComponent
      loading={
        getMyServiceStacksQueryLoading || addMyServiceStackMutationLoading
      }
      stacks={getMyServiceStacksQueryData?.getMyServiceStacks || []}
      addStackError={addStackError}
      resetAddStackError={resetAddStackError}
      navigateToEditStack={navigateToEditStack}
      addStackId={addStackId}
      setAdddStackId={setAdddStackId}
      addStackType={addStackType}
      setAdddStackType={setAdddStackType}
      addStack={addStack}
    />
  );
};
