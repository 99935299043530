import Logout from "@mui/icons-material/Logout";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { User } from "../../services/backend_gateway/__generated__/backend_gateway-types";

export const ProfileComponent = (props: {
  loading: boolean;
  user: User | null;
  signOut: () => void;
}) => {
  const { loading, user, signOut } = props;

  return (
    <PageFrame>
      <PageTitle>My profile</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Typography>Name: {user?.name}</Typography>
      <Typography>Email: {user?.email}</Typography>
      <Typography>Role: {user?.role}</Typography>
      <Typography>OrganizationId: {user?.organizationId}</Typography>

      <Button startDecorator={<Logout />} onClick={() => signOut()}>
        Logout
      </Button>
    </PageFrame>
  );
};
