import Security from "@mui/icons-material/Security";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Snackbar from "@mui/joy/Snackbar";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { PageFrame } from "../../../components/page_frame";
import { PageTitle } from "../../../components/page_title";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageLoading } from "../../../components/page_loading";
import Grid from "@mui/joy/Grid";

export const AddNewDeploymentComponent = (props: {
  loading: boolean;
  deployments: Deployment[];
  addDeploymentError: string | null;
  resetAddDeploymentError: () => void;
  addDeplomymentId: string;
  setAddDeploymentId: React.Dispatch<React.SetStateAction<string>>;
  addDeployment: (deploymentId: string) => void;
}) => {
  const {
    loading,
    deployments,
    addDeploymentError,
    resetAddDeploymentError,
    addDeplomymentId,
    setAddDeploymentId,
    addDeployment,
  } = props;
  const validId =
    addDeplomymentId === "" ||
    (deployments.map((d) => d.id).indexOf(addDeplomymentId) === -1 &&
      /^[a-z,0-9]+$/.test(addDeplomymentId));
  return (
    <PageFrame>
      <PageTitle>Create a new environment</PageTitle>
      <Typography>
        An environment is a fully-featured complete set of hosted instances of
        the services of your application. Environments are independent, sharing
        no resources.
      </Typography>
      <PageLoading loading={loading} />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={addDeploymentError !== null}
        onClose={resetAddDeploymentError}
      >
        {addDeploymentError}
      </Snackbar>

      <Grid container spacing={2} sx={{ flexGrow: 1, pt: 4 }}>
        <Grid xs={12}>
          <Typography level="h3" sx={{ pb: 2 }}>
            Choose an Environment ID
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            The environment id can be any string of lower-case letters and
            numbers. It refers to your deployment. It's a common practice to
            have environments for each major step in your software development
            life cycle. In this case the environment id can be the name of the
            development phase the environment is used for, like{" "}
            <Typography variant="outlined">development</Typography> or{" "}
            <Typography variant="outlined">prod</Typography>.
          </Typography>
          <FormControl sx={{ maxWidth: 350, pt: 2 }}>
            <FormLabel>New Environment ID</FormLabel>
            <Stack spacing={0.5}>
              <Input
                startDecorator={<Security />}
                endDecorator={
                  <Button
                    onClick={() => addDeployment(addDeplomymentId)}
                    disabled={!validId}
                  >
                    Create
                  </Button>
                }
                placeholder="eg. dev"
                value={addDeplomymentId}
                onChange={(event) => setAddDeploymentId(event.target.value)}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {validId ? "" : "Invalid value"}
              </Typography>
            </Stack>
          </FormControl>
        </Grid>
      </Grid>
    </PageFrame>
  );
};
