import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
  NormalizedCacheObject,
} from "@apollo/client";
import { apiConfig } from "../../configs/api";
import { AuthenticationService } from "./api_gateway_authentication";

export const getNewApiClient = (
  authenticationService: AuthenticationService = AuthenticationService.getInstance()
): ApolloClient<NormalizedCacheObject> => {
  // Best recreated a new one at every authentication event
  // to clear the in memory cache. The service takes care of that.

  const { apiUrl, graphqlPath } = apiConfig;
  const uri = `${apiUrl}${graphqlPath}`;

  const httpLink = new HttpLink({
    uri: uri,
    fetch: authenticationService.awsGraphqlFetch,
  });

  const rootOrganizationSelectorMiddleware = new ApolloLink(
    (operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          "X-WCA-OrganizationId":
            localStorage.getItem("selectedOrganizationId") || "",
        },
      }));

      return forward(operation);
    }
  );

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    // uri: uri,
    link: concat(rootOrganizationSelectorMiddleware, httpLink),
    cache: new InMemoryCache(),
  });
  return client;
};
