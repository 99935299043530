import { useContext, useState } from "react";
import { OrganizationsComponent } from "./component";
import { BgwContext } from "../../contexts/backend_gateway/context";
import {
  useAddOrganizationMutation,
  useGetAllOrganizationsQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";

export const OrganizationsContainer = (props: {}) => {
  const { bgwService } = useContext(BgwContext);

  const {
    data: getAllOrganizationsData,
    loading: getAllOrganizationsLoading,
    error: getAllOrganizationsError,
  } = useGetAllOrganizationsQuery({
    client: bgwService.getClient(),
  });

  const [
    addOrganizationMutation,
    {
      loading: addOrganizationMutationLoading,
      error: addOrganizationMutationError,
    },
  ] = useAddOrganizationMutation({
    client: bgwService.getClient(),
  });

  const [addNewOrganizationName, setAddNewOrganizationName] =
    useState<string>("");
  const [addOrganizationError, setAddOrganizationError] = useState<
    string | null
  >(null);

  if (getAllOrganizationsError) {
    console.error(getAllOrganizationsError);
  }
  if (addOrganizationMutationError) {
    console.error(addOrganizationMutationError);
  }

  const navigate = useNavigate();

  const navigateToEditOrganization = (organizationId: string) => {
    navigate(`/organizations/${organizationId}`, { replace: true });
  };

  const addNewOrganization = (organizationId: string) => {
    if (
      (
        getAllOrganizationsData?.getAllOrganizations?.map((o) => o?.id) || []
      ).includes(organizationId)
    ) {
      setAddOrganizationError("Organization ID already taken");
      return;
    }
    addOrganizationMutation({
      variables: {
        organizationId,
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getAllOrganizations(existingOrganizations = []) {
              const newOrganizationRef = cache.writeFragment({
                data: data?.addOrganization,
                fragment: gql`
                  fragment NewOrganization on Organization {
                    id
                    name
                  }
                `,
              });
              return [...existingOrganizations, newOrganizationRef];
            },
          },
        });
      },
      refetchQueries: ["GetAllOrganizations"],
      onCompleted: (data) => {
        if (data?.addOrganization !== undefined) {
          navigateToEditOrganization(data!.addOrganization!.id);
          setAddNewOrganizationName("");
        }
      },
      onError: (error) => {
        setAddOrganizationError(`Failed to add ${organizationId}.`);
        console.error(error);
      },
    });
  };

  const resetAddNewOrganizationError = () => {
    setAddOrganizationError(null);
  };

  return (
    <OrganizationsComponent
      loading={getAllOrganizationsLoading || addOrganizationMutationLoading}
      organizations={getAllOrganizationsData?.getAllOrganizations || []}
      navigateToEditOrganization={navigateToEditOrganization}
      addNewOrganizationName={addNewOrganizationName}
      setAddNewOrganizationName={setAddNewOrganizationName}
      addNewOrganization={addNewOrganization}
      addOrganizationError={addOrganizationError}
      resetAddNewOrganizationError={resetAddNewOrganizationError}
    />
  );
};
