import Business from "@mui/icons-material/Business";
import Button from "@mui/joy/Button";
import Table from "@mui/joy/Table";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Snackbar from "@mui/joy/Snackbar";
import { Organization } from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageLoading } from "../../components/page_loading";
import { TableFrame } from "../../components/table_frame";

export const OrganizationsComponent = (props: {
  loading: boolean;
  organizations: Organization[];
  navigateToEditOrganization: (organizationId: string) => void;
  addNewOrganizationName: string;
  setAddNewOrganizationName: React.Dispatch<React.SetStateAction<string>>;
  addNewOrganization: (organizationId: string) => void;
  addOrganizationError: string | null;
  resetAddNewOrganizationError: () => void;
}) => {
  const {
    loading,
    organizations,
    navigateToEditOrganization,
    addNewOrganizationName,
    setAddNewOrganizationName,
    addNewOrganization,
    addOrganizationError,
    resetAddNewOrganizationError,
  } = props;
  return (
    <PageFrame>
      <PageTitle>Organizations</PageTitle>
      <PageLoading loading={loading} />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={addOrganizationError !== null}
        onClose={resetAddNewOrganizationError}
      >
        {addOrganizationError}
      </Snackbar>

      <TableFrame cellHeight={40} tableHeight={200}>
        <Table hoverRow stickyHeader>
          <thead>
            <tr>
              <th style={{ width: "150" }}>id</th>
              <th>name</th>
            </tr>
          </thead>
          <tbody>
            {organizations.map((org) => (
              <tr
                key={org.id}
                onClick={() => navigateToEditOrganization(org.id)}
              >
                <td>{org.id}</td>
                <td>{org.name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableFrame>

      <FormControl>
        <FormLabel>Add new organization</FormLabel>
        <Input
          startDecorator={<Business />}
          endDecorator={
            <Button onClick={() => addNewOrganization(addNewOrganizationName)}>
              Add
            </Button>
          }
          placeholder="organization"
          value={addNewOrganizationName}
          onChange={(event) => setAddNewOrganizationName(event.target.value)}
        />
        <FormHelperText>No spaces, all lower-case</FormHelperText>
      </FormControl>
    </PageFrame>
  );
};
