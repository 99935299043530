import Sheet from "@mui/joy/Sheet";
import { ReactNode } from "react";
import { colors } from "../../layouts/drawer_layout/colors";

export const TableFrame = (props: {
  children: ReactNode;
  cellHeight: number;
  tableHeight: number;
}) => {
  const { children, cellHeight, tableHeight } = props;
  // 40px
  // 200
  return (
    <Sheet
      sx={{
        "--TableCell-height": `${cellHeight}px`,
        // the number is the amount of the header rows.
        "--TableHeader-height": "calc(1 * var(--TableCell-height))",
        height: `${tableHeight}px`,
        overflow: "auto",
        background: (
          theme
        ) => `linear-gradient(${theme.vars.palette.background.surface} 30%, rgba(255, 255, 255, 0)),
            linear-gradient(rgba(255, 255, 255, 0), ${theme.vars.palette.background.surface} 70%) 0 100%,
            radial-gradient(
              farthest-side at 50% 0,
              rgba(0, 0, 0, 0.12),
              rgba(0, 0, 0, 0)
            ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.12),
                rgba(0, 0, 0, 0)
              )
              0 100%`,
        backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "local, local, scroll, scroll",
        backgroundPosition:
          "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
        backgroundColor: "background.surface",
        border: `1px solid ${colors.grey4}`,
        borderRadius: "4px",
      }}
    >
      {children}
    </Sheet>
  );
};
