import { createContext } from "react";
import { BgwService } from "../../services/backend_gateway/service";
import { BgwType } from "./type";

const defaultValue: BgwType = {
  bgwService: BgwService.getInstance(),
  selectedOrganizationId: null,
  setSelectedOrganizationId: () => {},
};

export const BgwContext = createContext(defaultValue);
