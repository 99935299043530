import { useContext, useEffect, useMemo, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import {
  Deployment,
  useCreateMyDeploymentStackMutation,
  useFindMyDeploymentStackValuesQuery,
  useGetMyDeploymentStackStatusQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { StackSummaryComponent } from "./stack_summary";

export const DeploymentDeployer = (props: {
  deploymentId: string;
  deployment: Deployment | null;
  deploymentEnabled: boolean;
  setStackCompleted: (status: boolean) => void;
}) => {
  const { deploymentId, deployment, deploymentEnabled, setStackCompleted } =
    props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: findMyDeploymentStackValuesData,
    loading: findMyDeploymentStackValuesLoading,
    refetch: findMyDeploymentStackValuesRefetch,
  } = useFindMyDeploymentStackValuesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const stackId = useMemo(
    () => findMyDeploymentStackValuesData?.findMyDeploymentStackValues || null,
    [findMyDeploymentStackValuesData]
  );

  const {
    data: getMyDeploymentStackStatusData,
    loading: getMyDeploymentStackStatusLoading,
    refetch: getMyDeploymentStackStatusRefetch,
  } = useGetMyDeploymentStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });
  const stackStatus = useMemo(
    () => getMyDeploymentStackStatusData?.getMyDeploymentStackStatus?.status,
    [getMyDeploymentStackStatusData]
  );

  const [triggeringStack, setTriggeringStack] = useState<boolean>(false);

  const [createMyDeploymentStack, { loading: createMyDeploymentStackLoading }] =
    useCreateMyDeploymentStackMutation({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
      onCompleted: () => {
        setTimeout(async () => {
          await getMyDeploymentStackStatusRefetch();
          setTriggeringStack(false);
          findMyDeploymentStackValuesRefetch();
        }, 3000);
      },
    });

  // Start deployment
  useEffect(() => {
    if (stackStatus === null && !triggeringStack && deploymentEnabled) {
      setTriggeringStack(true);
      createMyDeploymentStack({});
    }
  }, [
    stackStatus,
    deploymentEnabled,
    createMyDeploymentStack,
    triggeringStack,
  ]);

  // Check deployment progress
  useEffect(() => {
    const intervalId = setInterval(() => {
      if ((stackStatus || "").indexOf("IN_PROGRESS") > -1) {
        getMyDeploymentStackStatusRefetch();
        findMyDeploymentStackValuesRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    stackStatus,
    getMyDeploymentStackStatusRefetch,
    findMyDeploymentStackValuesRefetch,
  ]);

  // Callback
  useEffect(() => {
    setStackCompleted(
      ["UPDATE_COMPLETE", "CREATE_COMPLETE", "ROLLBACK_COMPLETE"].includes(
        stackStatus || ""
      )
    );
  }, [stackStatus, setStackCompleted]);

  const link = `https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/events?filteringText=&filteringStatus=active&viewNested=true&stackId=${stackId}`;

  return (
    <StackSummaryComponent
      loading={
        findMyDeploymentStackValuesLoading ||
        getMyDeploymentStackStatusLoading ||
        createMyDeploymentStackLoading
      }
      deploymentEnabled={deploymentEnabled}
      title="Secret Management"
      link={link}
      status={stackStatus}
    />
  );
};
