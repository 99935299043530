import { useContext, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AddNewDeploymentComponent } from "./component";
import {
  useAddMyDeploymentMutation,
  useGetMyDeploymentsQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";

export const AddNewDeploymentContainer = (props: {}) => {
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const [addDeploymentError, setAddDeploymentError] = useState<string | null>(
    null
  );
  const [addDeplomymentId, setAddDeploymentId] = useState<string>("");

  const [
    addMyDeploymentMutation,
    {
      loading: addMyDeploymentMutationLoading,
      error: addMyDeploymentMutationError,
    },
  ] = useAddMyDeploymentMutation({
    client: bgwService.getClient(),
  });

  const resetAddDeploymentError = () => {
    setAddDeploymentError(null);
  };

  const navigate = useNavigate();

  const navigateToWizard = (deploymentId: string) => {
    navigate(`/deployment_steps/${deploymentId}/start`, { replace: true });
  };

  const addDeployment = () => {
    addMyDeploymentMutation({
      variables: {
        deploymentInput: {
          id: addDeplomymentId,
          name: "",
          accountId: "",
          awsRegion: "",
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyDeployments(existingDeployments = []) {
              const newDeploymentRef = cache.writeFragment({
                data: data?.addMyDeployment,
                fragment: gql`
                  fragment NewDeployment on Deployment {
                    id
                  }
                `,
              });
              return [...existingDeployments, newDeploymentRef];
            },
          },
        });
      },
      refetchQueries: ["GetMyDeployments"],
      onCompleted: (data) => {
        if (data?.addMyDeployment !== undefined) {
          navigateToWizard(data!.addMyDeployment!.id);
          setAddDeploymentId("");
        }
      },
      onError: (error) => {
        setAddDeploymentError(`Failed to add ${addDeplomymentId}.`);
        console.error(error);
      },
    });
  };

  if (getMyDeploymentsError) {
    console.error(getMyDeploymentsError);
  }

  if (addMyDeploymentMutationError) {
    console.error(addMyDeploymentMutationError);
  }

  return (
    <AddNewDeploymentComponent
      loading={getMyDeploymentsLoading || addMyDeploymentMutationLoading}
      deployments={getMyDeploymentsData?.getMyDeployments || []}
      addDeploymentError={addDeploymentError}
      resetAddDeploymentError={resetAddDeploymentError}
      addDeplomymentId={addDeplomymentId}
      setAddDeploymentId={setAddDeploymentId}
      addDeployment={addDeployment}
    />
  );
};
