import Box from "@mui/joy/Box";
import { ReactNode } from "react";

export const PageFrame = (props: { children?: ReactNode; sx?: any }) => {
  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 1.5,
          m: 5,
          minWidth: 0,
          maxWidht: "100%",
          // overflow: "hidden",
        },
      ]}
      {...props}
    />
  );
};
