import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";

export const PageLoading = (props: { loading: boolean }) => {
  const { loading } = props;
  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
  return null;
};
