import { extendTheme } from "@mui/joy/styles";

export const theme = extendTheme({
  cssVarPrefix: "",

  fontFamily: {
    body: "var(--fontFamily-fallback)",
    display: "var(--fontFamily-fallback)",
    code: "Source Code Pro,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace",
    fallback:
      '"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
});
