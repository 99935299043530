import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GitHubIcon from "@mui/icons-material/GitHub";
import Alert from "@mui/joy/Alert";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Deployment,
  GitConnection,
  ServiceStack,
  StackStatus,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import GitLabIcon from "../../../components/logos/gitlab-logo-500.svg";
import BitBucketIcon from "../../../components/logos/bitbucket-logo.svg";
import { PageLoading } from "../../../components/page_loading";

export const AddServiceComponent = (props: {
  loading: boolean;
  stacks: ServiceStack[];
  deployment: Deployment | null;
  stackStatus: StackStatus | null;
  gitConnectionArns: GitConnection[];
  deployEcsService: (
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    dockerfilePath: string,
    servicePort: number,
    healthCheckPath: string | undefined
  ) => void;
  refetchStackStatus: () => void;
  stackId: string;
  setStackId: React.Dispatch<React.SetStateAction<string>>;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  navigateWhenFinished: () => void;
}) => {
  const {
    loading,
    stacks,
    deployment,
    stackStatus,
    gitConnectionArns,
    deployEcsService,
    refetchStackStatus,
    stackId,
    setStackId,
    navigateToPrevious,
    navigateWhenFinished,
  } = props;
  const validId = useMemo(() => {
    return (
      stackId === "" ||
      (stacks.map((s) => s.id).indexOf(stackId) === -1 &&
        /^[a-z,0-9]+$/.test(stackId))
    );
  }, [stackId, stacks]);

  const [gitConnectionArn, setGitConnectionArn] = useState<string>("");
  const [gitRepositoryId, setGitRepositoryId] = useState<string>("");
  const [gitBranch, setGitBranch] = useState<string>("");
  const [dockerfilePath, setDockerfilePath] = useState<string>("");
  const [servicePort, setServicePort] = useState<number>(80);
  const [healthCheckPath, setHealthCheckPath] = useState<string | undefined>(
    undefined
  );

  const servicePortInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (stackStatus !== null && (stackStatus.parametersJSON || null) !== null) {
      try {
        const params = JSON.parse(stackStatus.parametersJSON!);
        setGitConnectionArn(params.GitConnectionArn || "");
        setGitRepositoryId(params.GitRepositoryId || "");
        setGitBranch(params.GitBranch || "");
        setDockerfilePath(params.DockerfilePath || "");
        setServicePort(parseInt(params.ServicePort) || 80);
        setHealthCheckPath(params.HealthCheckPath || "/health_check");
      } catch (err) {
        console.info("Failed to parse parametersJSON");
      }
    }
  }, [stackStatus]);

  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            Deploying your Web-server
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step will create a CICD pipeline in your AWS account, that
            pulls sources from your git provider using a git connection that has
            already been set up, and then deploys it as a docker container using
            the AWS ECS Fargate service.
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            Specify the git repository and branch of your web-server that you
            want to deploy. Set the service port to the exposed port of your
            docker container, that your web-server listens to.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>New Service ID</FormLabel>
            <Stack spacing={0.5}>
              <Input
                placeholder="eg. engine"
                value={stackId}
                onChange={(event) => setStackId(event.target.value)}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {validId ? "" : "Invalid value"}
              </Typography>
            </Stack>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Select your git connection</FormLabel>
            <Select
              placeholder="Select Git Connection"
              value={gitConnectionArn}
              slotProps={{
                listbox: {
                  sx: {
                    maxHeight: 300,
                    overflow: "auto", // required for scrolling
                    zIndex: 1201, // Because of the floating drawer on mobile
                  },
                },
              }}
            >
              {gitConnectionArns?.map((conn, i) => (
                <Option
                  value={conn.id}
                  key={conn.id || i}
                  onClick={() => {
                    setGitConnectionArn(conn.id || "");
                  }}
                >
                  <ListItemDecorator>
                    {conn.providerType === "GitHub" ||
                    conn.providerType === "GitHubEnterpriseServer" ? (
                      <GitHubIcon />
                    ) : conn.providerType === "Bitbucket" ? (
                      <Avatar src={BitBucketIcon} />
                    ) : (
                      <Avatar src={GitLabIcon} />
                    )}
                  </ListItemDecorator>
                  {conn.name}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              Select the connection to your git provider that has the repository
              that you want to deploy
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Git Repository</FormLabel>
            <Input
              value={gitRepositoryId}
              onChange={(event) => {
                setGitRepositoryId(event.target.value);
              }}
            />
            <FormHelperText>
              The owner and name of the repo expected in the following format:
              some-user/my-repo
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormLabel>Git Branch</FormLabel>
          <Input
            value={gitBranch}
            onChange={(event) => {
              setGitBranch(event.target.value);
            }}
          />
          <FormHelperText>The branch in the repo to deploy</FormHelperText>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Dockerfile path</FormLabel>
            <Input
              value={dockerfilePath}
              onChange={(event) => {
                setDockerfilePath(event.target.value);
              }}
            />
            <FormHelperText>
              The relative path in the repo to the Dockerfile. For example{" "}
              {stackId}/Dockerfile
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Service port</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: servicePortInputRef,
                  min: 0,
                  max: 65535,
                  step: 1,
                },
              }}
              value={servicePort}
              onChange={(event) => {
                setServicePort(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              The port, which is exposed on the Docker container and accepts
              HTTP requests.
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Health-check path</FormLabel>
            <Input
              value={healthCheckPath}
              onChange={(event) => {
                setHealthCheckPath(event.target.value);
              }}
            />
            <FormHelperText>
              The URL path that responds with 2xx to HTTP GET requests
            </FormHelperText>
          </FormControl>
        </Grid> */}
        {!loading &&
          stackStatus?.status !== null &&
          stackStatus?.status !== undefined && (
            <Grid xs={12}>
              <Alert
                color={
                  stackStatus?.status === "CREATE_COMPLETE" ||
                  stackStatus?.status === "UPDATE_COMPLETE"
                    ? "success"
                    : (stackStatus?.status || "").indexOf("CREATE") > -1 ||
                      (stackStatus?.status || "").indexOf("UPDATE") > -1
                    ? "primary"
                    : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                    ? "danger"
                    : "neutral"
                }
                variant="soft"
                sx={{ mb: 2 }}
                startDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ? (
                    <CircularProgress
                      size="sm"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                    />
                  ) : null
                }
                endDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") === -1 ? (
                    <Button
                      size="sm"
                      variant="soft"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                      onClick={() => {
                        refetchStackStatus();
                      }}
                    >
                      Refresh
                    </Button>
                  ) : null
                }
              >
                Deployment status: {stackStatus?.status}
                <Link
                  href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=waffle-${deployment?.id}-${stackId}-ecs-cicd`}
                >
                  Details
                </Link>
              </Alert>
            </Grid>
          )}
        <Grid xs={12}>
          <Button
            onClick={() =>
              deployEcsService(
                gitConnectionArn,
                gitRepositoryId,
                gitBranch,
                dockerfilePath,
                servicePort,
                healthCheckPath
              )
            }
            disabled={
              loading || (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1
            }
          >
            {(stackStatus?.status || null) === null ? "Deploy" : "Update"}{" "}
            Service
          </Button>
        </Grid>

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          <Button
            sx={{ mt: 4 }}
            onClick={() => navigateWhenFinished()}
            disabled={
              !(
                stackStatus?.status === "CREATE_COMPLETE" ||
                stackStatus?.status === "UPDATE_COMPLETE"
              )
            }
            endDecorator={<ChevronRightIcon />}
          >
            See the Dashboard
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
