import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Alert from "@mui/joy/Alert";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { PageLoading } from "../../../components/page_loading";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import ClickValidateImg from "./click-validate.png";
import CreateRecordsImg from "./create-records.png";

export const DeploymentStepsCertificateComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  queryError: string[];
  removeQueryError: (i: number) => void;
  requestCertificate: () => void;
  certificateArn: string | null;
  certificateStatus: string | null;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  disableRequestButton: boolean;
}) => {
  const {
    loading,
    deployment,
    queryError,
    removeQueryError,
    requestCertificate,
    certificateArn,
    certificateStatus,
    navigateToNext,
    navigateToPrevious,
    disableRequestButton,
  } = props;
  return (
    <>
      <PageLoading loading={loading || disableRequestButton} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            SSL Certificate
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about creating an SSL Certificate for the environment's
            domain name{" "}
            <Typography variant="outlined" color="primary" noWrap>
              {deployment?.fullDomainName}
            </Typography>{" "}
            using AWS Certificate Manager in your AWS account.
          </Typography>
        </Grid>
        {certificateArn === null && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              Request a Certificate
            </Typography>
            <Button
              size="lg"
              disabled={
                loading || certificateArn !== null || disableRequestButton
              }
              onClick={() => requestCertificate()}
              sx={{ mt: 2 }}
            >
              Request a Certificate
            </Button>
          </Grid>
        )}
        {certificateStatus === "PENDING_VALIDATION" && (
          <>
            <Grid xs={12}>
              <Typography level="h3" sx={{ py: 2 }}>
                Requesting a Certificate:{" "}
                <Chip size="lg" color="neutral">
                  In Progress
                </Chip>
              </Typography>
              <Typography level="body-md" sx={{ py: 1 }}>
                Requesting the certificate is in progress. In order for the SSL
                certificate to work, you need to proove that you have the
                ownership for the domain name that it stands for. This is done
                by creating a DNS record in the {deployment?.fullDomainName}{" "}
                hosted zone in Route 53 in your AWS account. Please validate it
                manually by going to the{" "}
                <Link
                  href={`https://${
                    deployment?.awsRegion
                  }.console.aws.amazon.com/acm/home?region=${
                    deployment?.awsRegion
                  }#/certificates/${certificateArn?.split("/")[1]}`}
                >
                  AWS Console
                </Link>{" "}
                and following the steps below.
              </Typography>
            </Grid>
            <Grid xs={12} sm={6}>
              <Card variant="outlined">
                <AspectRatio ratio="3840/1914">
                  <img src={ClickValidateImg} alt="" />
                </AspectRatio>
                <div>
                  <Typography level="title-md">
                    1. Create Records in Route 53
                  </Typography>
                  <Typography level="body-sm">
                    Open the{" "}
                    <Link
                      href={`https://${
                        deployment?.awsRegion
                      }.console.aws.amazon.com/acm/home?region=${
                        deployment?.awsRegion
                      }#/certificates/${certificateArn?.split("/")[1]}`}
                    >
                      Certificate Manager
                    </Link>{" "}
                    and then click the "Create Records in Route 53" button.
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid xs={12} sm={6}>
              <Card variant="outlined">
                <AspectRatio ratio="3840/1918">
                  <img src={CreateRecordsImg} alt="" />
                </AspectRatio>
                <div>
                  <Typography level="title-md">2. Create Records</Typography>
                  <Typography level="body-sm">
                    Click the "Create Records" button. After a few minutes the
                    certificate is supposed to show as validated. Then you'll be
                    able to proceed to the next step.
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Alert color="neutral" variant="soft" sx={{ my: 2 }}>
              Heads-up: Validation will only be successful if the DNS NS records
              were successfully created in the previous step, as described.
            </Alert>
          </>
        )}
        {(certificateStatus === "ISSUED" ||
          certificateStatus === "INACTIVE") && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              Requesting a Certificate{" "}
              <Chip size="lg" color="success">
                Completed
              </Chip>
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Requesting the certificate has been successful. Details can be
              reviewed in the{" "}
              <Link
                href={`https://${
                  deployment?.awsRegion
                }.console.aws.amazon.com/acm/home?region=${
                  deployment?.awsRegion
                }#/certificates/${certificateArn?.split("/")[1]}`}
              >
                Certificate Manager
              </Link>
            </Typography>
          </Grid>
        )}
        {!["ISSUED", "INACTIVE", "PENDING_VALIDATION", ""].includes(
          certificateStatus || ""
        ) && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              Requesting a Certificate
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              The certificate is in an unexpected status ({certificateStatus}).
              Please fix it manually using the{" "}
              <Link
                href={`https://${
                  deployment?.awsRegion
                }.console.aws.amazon.com/acm/home?region=${
                  deployment?.awsRegion
                }#/certificates/${certificateArn?.split("/")[1]}`}
              >
                Certificate Manager
              </Link>
            </Typography>
          </Grid>
        )}

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={
                !["ISSUED", "INACTIVE"].includes(certificateStatus || "")
              }
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
