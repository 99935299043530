import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReplayIcon from "@mui/icons-material/Replay";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { PageLoading } from "../../../components/page_loading";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsDeploymentComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  queryError: string[];
  removeQueryError: (i: number) => void;
  checkStackStatus: () => void;
  stackId: string | null;
  stackStatus: string | null;
  deployStack: () => void;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  disableDeployButton: boolean;
}) => {
  const {
    loading,
    deployment,
    queryError,
    removeQueryError,
    checkStackStatus,
    stackId,
    stackStatus,
    deployStack,
    navigateToNext,
    navigateToPrevious,
    disableDeployButton,
  } = props;
  const stackIdCreated = stackId || `waffle-${deployment?.id}-deployment`;
  return (
    <>
      <PageLoading loading={loading || disableDeployButton} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            Utilities
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about creating a secret in your AWS account using the
            AWS Secrets Manager that can handle information privately, only
            accessible for the services in this environment. You can use this
            secret to store for example credentials of 3rd party services that
            are used by multiple services in this environment.
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            When you deploy services into your environments, they will also have
            managed secrets that are only accessible for them individually.
            Consider storing sensitive information that are only relevant for a
            single service in their specific secret.
          </Typography>
        </Grid>
        {(stackId !== null || stackStatus !== null) && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              You already have a Utilities stack
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              The status of the deployment is:{" "}
              <Typography variant="outlined" color="primary" noWrap>
                {stackStatus}
              </Typography>{" "}
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Deployment details here:{" "}
              <Link
                href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=${stackIdCreated}`}
              >
                CloudFormation stacks
              </Link>
              .
            </Typography>
            <Button
              size="sm"
              variant="soft"
              disabled={loading}
              onClick={() => checkStackStatus()}
              sx={{ mt: 2 }}
              startDecorator={<ReplayIcon />}
            >
              Update status
            </Button>
          </Grid>
        )}
        {stackId === null && stackStatus === null && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              Create your Utilities Stack
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Waffle uses CloudFormation to set the Utilities up in your AWS
              account.
            </Typography>
            <FormControl sx={{ maxWidth: 350, pt: 2 }}></FormControl>
            <Button
              size="lg"
              disabled={loading || disableDeployButton}
              onClick={() => deployStack()}
              sx={{ mt: 2 }}
            >
              Create Utilities Stack
            </Button>
          </Grid>
        )}

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={loading || stackId === null}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
          {navigateToNext === null && stackStatus === "CREATE_COMPLETE" && (
            <Button sx={{ mt: 4 }} variant="plain">
              🎉 All set!
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
