import { useParams } from "react-router-dom";
import { Page } from "../../layouts/page";
import { ServiceStatusesContainer } from "./container";
import { AddNewServiceContainer } from "./add_new_service/container";

export const ServiceStatusesPage = () => {
  const params = useParams();
  const { stackId } = params;

  if (stackId === undefined) {
    return (
      <Page>
        <AddNewServiceContainer />
      </Page>
    );
  }
  return (
    <Page>
      <ServiceStatusesContainer stackId={stackId!} />
    </Page>
  );
};
