import { ReactNode } from "react";
import Box from "@mui/joy/Box";

import { FOOTER_HEIGHT } from "../constants";
import { colors } from "../../colors";

export const XsFooter = (props: { children?: ReactNode; sx?: any }) => {
  return (
    <Box
      {...props}
      sx={[
        {
          p: 0,
          m: 0,
          bgcolor: colors.white,
          display: {
            xs: "initial",
            sm: "none",
          },
          gridRow: 2,
          gridColumn: 1,
          height: FOOTER_HEIGHT,
          overflow: "hidden",
          borderTop: `2px solid ${colors.grey2}`,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      aria-label="xs-footer"
    />
  );
};
