import { Page } from "../../layouts/page";
import { ProfileContainer } from "./container";

export const ProfilePage = () => {
  return (
    <Page>
      <ProfileContainer />
    </Page>
  );
};
