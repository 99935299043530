import { useParams } from "react-router-dom";
import { Page } from "../../layouts/page";
import { DashboardContainer } from "./container";

export const DashboardPage = () => {
  const params = useParams();
  const { deploymentId } = params;

  if (deploymentId === undefined) {
    return null;
  }
  return (
    <Page>
      <DashboardContainer deploymentId={deploymentId!} />
    </Page>
  );
};
