import { fetchAuthSession } from "aws-amplify/auth";
// @ts-ignore
import aws4 from "aws4-tiny";
import { apiConfig } from "../../configs/api";

export class AuthenticationService {
  private static instance: AuthenticationService;

  private constructor() {}

  public static getInstance(): AuthenticationService {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = new AuthenticationService();
    }
    return AuthenticationService.instance;
  }

  awsGraphqlFetch = async (
    input: string | URL | Request,
    init?: RequestInit | undefined
  ) => {
    const { host, path, graphqlPath, region } = apiConfig;

    const session = await fetchAuthSession();

    const request = {
      host,
      method: "POST",
      path: `${path}${graphqlPath}`,
      region,
      service: "execute-api",
      // NOTE: options.body is a json string
      body: init?.body,
      headers: {
        "X-WCA-IdToken": session.tokens?.idToken,
        ...init?.headers,
      },
    };

    const signedRequest = aws4.sign(request, {
      ...session.credentials,
      identityId: session.identityId,
    });
    if (signedRequest?.headers && signedRequest?.headers["Host"]) {
      delete signedRequest.headers["Host"];
    }
    if (signedRequest?.headers && signedRequest?.headers["Content-Length"]) {
      delete signedRequest.headers["Content-Length"];
    }
    return fetch(input, signedRequest as RequestInit);
  };
}
