import "@fontsource/share-tech-mono";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";

export const RightPaneTitle = (props: { children?: ReactNode; sx?: any }) => {
  return (
    <Typography
      fontWeight={200}
      sx={{
        fontSize: {
          xs: "20px",
          sm: "20px",
        },
        fontFamily: "'Share Tech Mono'",
        textTransform: "uppercase",
        letterSpacing: ".4rem",
      }}
      {...props}
    />
  );
};
