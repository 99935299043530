import { useContext, useEffect, useState } from "react";
import { DeploymentStepsAlertsComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useCreateMyAlertsStackMutation,
  useFindMyAlertsStackValuesQuery,
  useGetMyDeploymentsQuery,
  useGetMyAlertsStackStatusQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsAlertsContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const {
    data: findMyAlertsStackValuesData,
    loading: findMyAlertsStackValuesLoading,
    refetch: findMyAlertsStackValuesRefetch,
  } = useFindMyAlertsStackValuesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const {
    data: getMyAlertsStackStatusData,
    loading: getMyAlertsStackStatusLoading,
    error: getMyAlertsStackStatusError,
    refetch: getMyAlertsStackStatusRefetch,
  } = useGetMyAlertsStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const [createMyAlertsStack, { loading: createMyAlertsStackLoading }] =
    useCreateMyAlertsStackMutation({
      client: bgwService.getClient(),
    });

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  useEffect(() => {
    if ((getMyDeploymentsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentsError!.message]);
    }
  }, [getMyDeploymentsError]);

  useEffect(() => {
    if ((getMyAlertsStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyAlertsStackStatusError!.message]);
    }
  }, [getMyAlertsStackStatusError]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyAlertsStackStatusData?.getMyAlertsStackStatus?.status || ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyAlertsStackStatusRefetch();
        findMyAlertsStackValuesRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    getMyAlertsStackStatusData,
    getMyAlertsStackStatusRefetch,
    findMyAlertsStackValuesRefetch,
  ]);

  const [disableDeployButton, setDisableDeployButton] =
    useState<boolean>(false);

  const deployStack = (emailNotificationList: string) => {
    setDisableDeployButton(true);
    createMyAlertsStack({
      variables: {
        deploymentId,
        emailNotificationList,
      },
      onCompleted: (data) => {
        setTimeout(checkStackStatus, 3000);
      },
      onError: (error) => {
        setQueryError((e) => [...e, `Failed deploy stack.`]);
        setDisableDeployButton(false);
        console.error(error);
      },
    });
  };

  const checkStackStatus = () => {
    getMyAlertsStackStatusRefetch();
    findMyAlertsStackValuesRefetch();
    setTimeout(() => {
      setDisableDeployButton(false);
    }, 3000);
  };

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  return (
    <DeploymentStepsAlertsComponent
      loading={
        getMyDeploymentsLoading ||
        findMyAlertsStackValuesLoading ||
        getMyAlertsStackStatusLoading ||
        createMyAlertsStackLoading
      }
      deployment={deployment || null}
      queryError={queryError}
      removeQueryError={removeQueryError}
      checkStackStatus={checkStackStatus}
      stackId={findMyAlertsStackValuesData?.findMyAlertsStackValues || null}
      stackStatus={
        getMyAlertsStackStatusData?.getMyAlertsStackStatus?.status || null
      }
      parametersJson={
        getMyAlertsStackStatusData?.getMyAlertsStackStatus?.parametersJSON ||
        null
      }
      deployStack={deployStack}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      disableDeployButton={disableDeployButton}
    />
  );
};
