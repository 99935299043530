import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";

export const RightPaneBackButton = (props: {
  labelText: string;
  onClick: () => void;
  sx?: any;
}) => {
  const { labelText, onClick, sx } = props;
  return (
    <Typography
      level="body-sm"
      startDecorator={
        <Link
          aria-labelledby="back-from-pane"
          fontSize="md"
          borderRadius="sm"
          onClick={onClick}
        >
          <KeyboardArrowLeft />
        </Link>
      }
      sx={[
        {
          mb: 2,
        },
        { ...sx },
      ]}
    >
      <Link
        aria-labelledby="back-from-pane"
        fontSize="md"
        borderRadius="sm"
        onClick={onClick}
      >
        {labelText}
      </Link>
    </Typography>
  );
};
