import { ReactNode } from "react";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import Grid from "@mui/joy/Grid";
import { colors } from "../../layouts/drawer_layout/colors";

export const DeploymentStepsComponent = (props: {
  deploymentId: string;
  stepComponent: ReactNode | null;
  stepperComponent: ReactNode | null;
}) => {
  const { deploymentId, stepComponent, stepperComponent } = props;
  return (
    <PageFrame>
      <PageTitle>Deploying the {deploymentId} environment</PageTitle>

      <Grid
        container
        spacing={2}
        sx={{
          flexGrow: 1,
          borderTop: `1px solid ${colors.grey4}`,
          pt: 4,
          mt: 1,
        }}
      >
        <Grid sx={{ display: { xs: "none", sm: "inherit" } }} sm={3}>
          {stepperComponent}
        </Grid>
        <Grid xs={12} sm={9}>
          {stepComponent}
        </Grid>
      </Grid>
    </PageFrame>
  );
};
