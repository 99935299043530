import { useNavigate, useParams } from "react-router-dom";
import { Page } from "../../layouts/page";
import { DeploymentStepsContainer } from "./container";
import { DEPLOYMENT_STEPS } from "./steps";
import { useEffect } from "react";
import { AddNewDeploymentContainer } from "./add_new_deployment/container";

export const DeploymentStepsPage = () => {
  const params = useParams();
  const { deploymentId, stepId } = params;

  const navigate = useNavigate();
  const validStep = DEPLOYMENT_STEPS.map((s) => s.id).includes(stepId || "");
  useEffect(() => {
    if (deploymentId && !validStep) {
      navigate(`/deployment_steps/${deploymentId}/${DEPLOYMENT_STEPS[0].id}`, {
        replace: true,
      });
    }
  }, [validStep, deploymentId, navigate]);
  if (deploymentId === undefined) {
    return (
      <Page>
        <AddNewDeploymentContainer />
      </Page>
    );
  }
  if (!validStep) {
    return null;
  }
  return (
    <Page>
      <DeploymentStepsContainer stepId={stepId!} deploymentId={deploymentId!} />
    </Page>
  );
};
