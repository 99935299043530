import { useContext } from "react";
import { BgwContext } from "../../../../contexts/backend_gateway/context";
import {
  Organization,
  useGetAllOrganizationsQuery,
  useWhoAmIQuery,
} from "../../../../services/backend_gateway/__generated__/backend_gateway-types";
import { OrganizationSelectorComponent } from "./component";

export const OrganizationSelectorContainer = (props: {
  navigateToRoot: () => void;
}) => {
  const { navigateToRoot } = props;
  const { selectedOrganizationId, setSelectedOrganizationId, bgwService } =
    useContext(BgwContext);

  const {
    data: whoAmIData,
    loading: whoAmILoading,
    error: whoAmIError,
  } = useWhoAmIQuery({
    client: bgwService.getClient(),
  });

  const {
    data: getAllOrganizationsData,
    loading: getAllOrganizationsLoading,
    error: getAllOrganizationsError,
  } = useGetAllOrganizationsQuery({
    client: bgwService.getClient(),
  });

  if (whoAmIError || getAllOrganizationsError) {
    console.error(whoAmIError, getAllOrganizationsError);
  }

  const setOrganization = (customerId: string | null) => {
    setSelectedOrganizationId(customerId);
  };

  return (
    <OrganizationSelectorComponent
      navigateToRoot={navigateToRoot}
      ograniztions={
        (getAllOrganizationsData?.getAllOrganizations || []) as Organization[]
      }
      selectedOrganizationId={selectedOrganizationId}
      setSelectedOrganizationId={setOrganization}
      role={whoAmIData?.whoAmI?.role || null}
      loading={whoAmILoading || getAllOrganizationsLoading}
    />
  );
};
