import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReplayIcon from "@mui/icons-material/Replay";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { PageLoading } from "../../../components/page_loading";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsApiComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  queryError: string[];
  removeQueryError: (i: number) => void;
  checkStackStatus: () => void;
  stackId: string | null;
  stackStatus: string | null;
  deployStack: () => void;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  disableDeployButton: boolean;
}) => {
  const {
    loading,
    deployment,
    queryError,
    removeQueryError,
    checkStackStatus,
    stackId,
    stackStatus,
    deployStack,
    navigateToNext,
    navigateToPrevious,
    disableDeployButton,
  } = props;
  const stackIdCreated = stackId || `waffle-${deployment?.id}-alerts`;
  return (
    <>
      <PageLoading loading={loading || disableDeployButton} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            API Gateway
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about creating a HTTP API Gateway in your AWS account.
            The API Gateway is a service maintained by AWS that handles
            authorization and also acts as a firewall. Your services will have
            endpoints generated automatically on the API Gateway.
          </Typography>
        </Grid>
        {(stackId !== null || stackStatus !== null) && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              You already have an API Gateway stack
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              The status of the deployment is:{" "}
              <Typography variant="outlined" color="primary" noWrap>
                {stackStatus}
              </Typography>{" "}
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Deployment details here:{" "}
              <Link
                href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=${stackIdCreated}`}
              >
                CloudFormation stacks
              </Link>
              .
            </Typography>
            <Button
              size="sm"
              variant="soft"
              disabled={loading}
              onClick={() => checkStackStatus()}
              sx={{ mt: 2 }}
              startDecorator={<ReplayIcon />}
            >
              Update status
            </Button>
          </Grid>
        )}
        {stackId === null && stackStatus === null && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              Create your API Gateway Stack
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Waffle uses CloudFormation to set the API Gateway up in your AWS
              account.
            </Typography>
            <FormControl sx={{ maxWidth: 350, pt: 2 }}></FormControl>
            <Button
              size="lg"
              disabled={loading || disableDeployButton}
              onClick={() => deployStack()}
              sx={{ mt: 2 }}
            >
              Create API Gateway Stack
            </Button>
          </Grid>
        )}

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={loading || stackId === null}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
