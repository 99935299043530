import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Snackbar from "@mui/joy/Snackbar";
import Switch from "@mui/joy/Switch";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useEffect, useRef, useState } from "react";
import { PageLoading } from "../../../components/page_loading";
import { awsRegions } from "../../../configs/aws_regions";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsSettingsComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  editDeployment: (
    id: string,
    name: string,
    accountId: string,
    awsRegion: string,
    defaultLogRetentionDays: number,
    defaultAlarmsEnabled: boolean,
    defaultDatabaseRetention: number,
    defaultRequireCicdManualApproval: boolean
  ) => void;
  editDeploymentError: string | null;
  resetEditDeploymentError: () => void;
  locked: boolean;
  done: boolean;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
}) => {
  const {
    loading,
    deployment,
    editDeployment,
    editDeploymentError,
    resetEditDeploymentError,
    locked,
    done,
    navigateToNext,
    navigateToPrevious,
  } = props;

  const [name, setName] = useState<string>("");
  const [accountId, setAccountId] = useState<string>("");
  const [awsRegion, setAwsRegion] = useState<string>("us-east-1");
  const [defaultLogRetentionDays, setDefaultLogRetentionDays] =
    useState<number>(7);
  const [defaultAlarmsEnabled, setDefaultAlarmsEnabled] =
    useState<boolean>(false);
  const [defaultDatabaseRetention, setDefaultDatabaseRetention] =
    useState<number>(1);
  const [
    defaultRequireCicdManualApproval,
    setDefaultRequireCicdManualApproval,
  ] = useState<boolean>(false);
  useEffect(() => {
    if (deployment !== null) {
      setName(deployment.name || "");
      setAccountId(deployment.accountId || "");
      setAwsRegion(deployment.awsRegion || "us-east-1");
      setDefaultLogRetentionDays(deployment.defaultLogRetentionDays || 7);
      setDefaultAlarmsEnabled(deployment.defaultAlarmsEnabled || false);
      setDefaultDatabaseRetention(deployment.defaultDatabaseRetention || 1);
      setDefaultRequireCicdManualApproval(
        deployment.defaultRequireCicdManualApproval || false
      );
    }
  }, [deployment]);

  const defaultLogRetentionDaysInputRef = useRef<HTMLInputElement | null>(null);
  // const defaultDatabaseRetentionInputRef = useRef<HTMLInputElement | null>(
  //   null
  // );

  return (
    <>
      <PageLoading loading={loading} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={editDeploymentError !== null}
        onClose={resetEditDeploymentError}
      >
        {editDeploymentError}
      </Snackbar>

      {!loading && locked && (
        <Alert color="warning" variant="soft">
          Most settings are locked, because already deployed.
        </Alert>
      )}

      {deployment && (
        <>
          <Typography level="h2" sx={{ pb: 2 }}>
            Environment settings
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            In this step you can specify settings and default behavior for the
            stacks that you deploy into it.
          </Typography>
          <FormControl sx={{ pt: 2 }}>
            <FormLabel>Environment ID</FormLabel>
            <Input value={deployment.id} disabled />
            <FormHelperText>Readonly</FormHelperText>
          </FormControl>

          <FormControl sx={{ pt: 2 }}>
            <FormLabel>Environment Name</FormLabel>
            <Input
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <FormHelperText>
              Human readable name for the environment
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ pt: 2 }}>
            <FormLabel>Account Id</FormLabel>
            <Input
              value={accountId}
              onChange={(event) => {
                setAccountId(event.target.value);
              }}
            />
            <FormHelperText>
              The ID of the AWS account you're planning to deploy into. If
              you're deploying to a sub-account of an AWS organization, then
              specify the sub-account's ID.
            </FormHelperText>
          </FormControl>

          <FormControl sx={{ pt: 2 }}>
            <FormLabel>AWS Region</FormLabel>
            <Stack spacing={0.5}>
              <Input
                value={awsRegion}
                disabled={locked}
                onChange={(event) => {
                  setAwsRegion(event.target.value);
                }}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {awsRegion === "" || awsRegions.includes(awsRegion)
                  ? ""
                  : "Invalid value"}
              </Typography>
            </Stack>
            <FormHelperText>A valid region name of AWS</FormHelperText>
          </FormControl>

          <FormControl sx={{ pt: 2 }}>
            <FormLabel>Default Log Retention</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: defaultLogRetentionDaysInputRef,
                  min: 1,
                  max: 365,
                  step: 1,
                },
              }}
              value={defaultLogRetentionDays}
              disabled={locked}
              onChange={(event) => {
                setDefaultLogRetentionDays(parseInt(event.target.value));
              }}
              endDecorator={<Typography fontSize="xs">days</Typography>}
            />
            <FormHelperText>Valid values are 1 to 365 days.</FormHelperText>
          </FormControl>

          <FormControl sx={{ pt: 2 }}>
            <FormLabel>Alarms enabled by default</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={defaultAlarmsEnabled}
                  disabled={locked}
                  onChange={(event) =>
                    setDefaultAlarmsEnabled(event.target.checked)
                  }
                />
              }
            >
              {defaultAlarmsEnabled ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Automated alarms in stacks typically cover being over a RAM and
              CPU threshold. Alarms are delivered in email by default. But you
              can add your own delivery methods, like sending them to Slack.
            </FormHelperText>
          </FormControl>

          {/* <FormControl sx={{ pt: 2 }}>
            <FormLabel>Default Database Backup Retention</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: defaultDatabaseRetentionInputRef,
                  min: 1,
                  max: 35,
                  step: 1,
                },
              }}
              value={defaultDatabaseRetention}
              disabled={locked}
              onChange={(event) => {
                setDefaultDatabaseRetention(parseInt(event.target.value));
              }}
              endDecorator={<Typography fontSize="xs">days</Typography>}
            />
            <FormHelperText>Valid values are 1 to 35 days.</FormHelperText>
          </FormControl> */}

          <FormControl sx={{ pt: 2 }}>
            <FormLabel>Manual CICD approval required by default</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={defaultRequireCicdManualApproval}
                  disabled={locked}
                  onChange={(event) =>
                    setDefaultRequireCicdManualApproval(event.target.checked)
                  }
                />
              }
            >
              {defaultRequireCicdManualApproval ? "Enabled" : "Disabled"}
            </Typography>
          </FormControl>

          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            {navigateToPrevious !== null && (
              <Button
                sx={{ mt: 4 }}
                onClick={() => navigateToPrevious()}
                startDecorator={<ChevronLeftIcon />}
              >
                Back
              </Button>
            )}

            <Button
              sx={{ mt: 4 }}
              onClick={() =>
                editDeployment(
                  deployment.id,
                  name,
                  accountId,
                  awsRegion,
                  defaultLogRetentionDays,
                  defaultAlarmsEnabled,
                  defaultDatabaseRetention,
                  defaultRequireCicdManualApproval
                )
              }
            >
              Update
            </Button>
            {navigateToNext !== null && (
              <Button
                sx={{ mt: 4 }}
                onClick={() => {
                  editDeployment(
                    deployment.id,
                    name,
                    accountId,
                    awsRegion,
                    defaultLogRetentionDays,
                    defaultAlarmsEnabled,
                    defaultDatabaseRetention,
                    defaultRequireCicdManualApproval
                  );
                  navigateToNext();
                }}
                disabled={!done}
                endDecorator={<ChevronRightIcon />}
              >
                Update and Next
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  );
};
