import "@fontsource/share-tech-mono";
import { AspectRatio, Box, Typography } from "@mui/joy";
import { colors } from "../../colors";
import { HEADER_HEIGHT } from "../../layout/constants";
import logo from "./logo.svg";

export const LogoText = (props: {}) => {
  return (
    <Box
      sx={{
        minHeight: `${HEADER_HEIGHT}px`,
        maxHeight: `${HEADER_HEIGHT}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        // justifyContent: "center",
        flexDirection: "row",
      }}
    >
      <AspectRatio
        objectFit="contain"
        variant="plain"
        ratio={`91/50`}
        sx={{
          ml: "8px",
          width: 42,
          opacity: 0.75,
        }}
      >
        <img src={logo} alt="Waffle" />
      </AspectRatio>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Share Tech Mono'",
            fontSize: "18px",
            ml: "15px",
            // letterSpacing: "0.13em",
            color: "black",
          }}
        >
          {" "}
        </Typography>
        <Typography
          sx={{
            // fontFamily: "'Share Tech Mono'",
            fontSize: "10px",
            fontWeight: 100,
            ml: "15px",
            // letterSpacing: "0.13em",
            color: colors.grey12,
          }}
        >
          © 2024 WaffleCode, Inc. | Terms | Privacy
        </Typography>
      </Box>
    </Box>
  );
};
