import { useContext, useEffect, useState } from "react";
import { DeploymentStepsVpcComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useCreateMyVpcStackMutation,
  useFindMyVpcStackValuesQuery,
  useGetMyDeploymentsQuery,
  useGetMyVpcStackStatusQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsVpcContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const {
    data: findMyVpcStackValuesData,
    loading: findMyVpcStackValuesLoading,
    refetch: findMyVpcStackValuesRefetch,
  } = useFindMyVpcStackValuesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const {
    data: getMyVpcStackStatusData,
    loading: getMyVpcStackStatusLoading,
    error: getMyVpcStackStatusError,
    refetch: getMyVpcStackStatusRefetch,
  } = useGetMyVpcStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const [createMyVpcStack, { loading: createMyVpcStackLoading }] =
    useCreateMyVpcStackMutation({
      client: bgwService.getClient(),
    });

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  useEffect(() => {
    if ((getMyDeploymentsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentsError!.message]);
    }
  }, [getMyDeploymentsError]);

  useEffect(() => {
    if ((getMyVpcStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyVpcStackStatusError!.message]);
    }
  }, [getMyVpcStackStatusError]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (getMyVpcStackStatusData?.getMyVpcStackStatus?.status || "").indexOf(
          "IN_PROGRESS"
        ) > -1
      ) {
        getMyVpcStackStatusRefetch();
        findMyVpcStackValuesRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    getMyVpcStackStatusData,
    getMyVpcStackStatusRefetch,
    findMyVpcStackValuesRefetch,
  ]);

  const [disableDeployButton, setDisableDeployButton] =
    useState<boolean>(false);

  const deployStack = (subnetIp: number | null) => {
    setDisableDeployButton(true);
    createMyVpcStack({
      variables: {
        deploymentId,
        subnetIp,
      },
      onCompleted: (data) => {
        setTimeout(checkStackStatus, 3000);
      },
      onError: (error) => {
        setQueryError((e) => [...e, `Failed deploy stack.`]);
        setDisableDeployButton(false);
        console.error(error);
      },
    });
  };

  const checkStackStatus = () => {
    getMyVpcStackStatusRefetch();
    findMyVpcStackValuesRefetch();
    setTimeout(() => {
      setDisableDeployButton(false);
    }, 3000);
  };

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  return (
    <DeploymentStepsVpcComponent
      loading={
        getMyDeploymentsLoading ||
        findMyVpcStackValuesLoading ||
        getMyVpcStackStatusLoading ||
        createMyVpcStackLoading
      }
      deployment={deployment || null}
      queryError={queryError}
      removeQueryError={removeQueryError}
      checkStackStatus={checkStackStatus}
      stackId={findMyVpcStackValuesData?.findMyVpcStackValues || null}
      stackStatus={getMyVpcStackStatusData?.getMyVpcStackStatus?.status || null}
      parametersJson={
        getMyVpcStackStatusData?.getMyVpcStackStatus?.parametersJSON || null
      }
      deployStack={deployStack}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      disableDeployButton={disableDeployButton}
    />
  );
};
