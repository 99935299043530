import { ReactNode } from "react";
import { DeploymentStepsRoleContainer } from "./role/container";
import { DeploymentStepsSettingsContainer } from "./settings/container";
import { DeploymentStepsGitConnectionContainer } from "./git_connection/container";
import { DeploymentStepsDomainNameContainer } from "./domain_name/container";
import { DeploymentStepsCertificateContainer } from "./certificate/container";
import { DeploymentStepsVpcContainer } from "./vpc/container";
import { DeploymentStepsApiContainer } from "./api/container";
import { DeploymentStepsAuthenticationContainer } from "./authentication/container";
import { DeploymentStepsAlertsContainer } from "./alerts/container";
import { DeploymentStepsDeploymentContainer } from "./deployment/container";

export type StepDef = {
  id: string;
  title: string;
  component: ReactNode;
};

export const DEPLOYMENT_STEPS = [
  {
    id: "settings",
    title: "Settings",
    component: DeploymentStepsSettingsContainer,
  },
  {
    id: "role",
    title: "IAM Role",
    component: DeploymentStepsRoleContainer,
  },
  {
    id: "git_connection",
    title: "Git Connection",
    component: DeploymentStepsGitConnectionContainer,
  },
  {
    id: "domain_name",
    title: "Domain Name",
    component: DeploymentStepsDomainNameContainer,
  },
  {
    id: "certificate",
    title: "SSL Certificate",
    component: DeploymentStepsCertificateContainer,
  },
  {
    id: "vpc",
    title: "Virtual Private Cloud",
    component: DeploymentStepsVpcContainer,
  },
  {
    id: "api",
    title: "API Gateway",
    component: DeploymentStepsApiContainer,
  },
  {
    id: "auth",
    title: "Authentication",
    component: DeploymentStepsAuthenticationContainer,
  },
  {
    id: "alerts",
    title: "Alerts",
    component: DeploymentStepsAlertsContainer,
  },
  {
    id: "deployment",
    title: "Utilities",
    component: DeploymentStepsDeploymentContainer,
  },
];
