import Add from "@mui/icons-material/Add";
import Business from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import Security from "@mui/icons-material/Security";

import Box from "@mui/joy/Box";
import Avatar from "@mui/joy/Avatar";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListSubheader from "@mui/joy/ListSubheader";
import Typography from "@mui/joy/Typography";

import {
  Deployment,
  ServiceStack,
  Features,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { OrganizationSelector } from "./organization_selector";
import { LogoText } from "./logo_text";
import { HEADER_HEIGHT } from "../layout/constants";
import { colors } from "../colors";

export const DrawerComponent = (props: {
  navigateToRoot: () => void;
  navigateToDashboard: (deploymentId: string) => void;
  navigateToDeploymentStatus: (newDeploymentId: string) => void;
  navigateToServiceStatus: (stackId: string) => void;
  navigateToAddDeployment: () => void;
  navigateToAddService: () => void;
  navigateToOrganizations: () => void;
  navigateToProfile: () => void;
  currentPathName: string | null;
  selectedOrganizationId: string | null;
  deploymentIdInPath: string | null;
  serviceIdInPath: string | null;
  userName: string | null;
  email: string | null;
  picture: string | null;
  role: string | null;
  loading: boolean;
  drawerWidth: number;
  features: Features | null;
  deployments: Deployment[];
  serviceStacks: ServiceStack[];
}) => {
  const {
    navigateToRoot,
    navigateToDashboard,
    navigateToDeploymentStatus,
    navigateToServiceStatus,
    navigateToAddDeployment,
    navigateToAddService,
    navigateToOrganizations,
    navigateToProfile,
    currentPathName,
    selectedOrganizationId,
    deploymentIdInPath,
    serviceIdInPath,
    userName,
    email,
    picture,
    role,
    loading,
    drawerWidth,
    deployments,
    serviceStacks,
  } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: `auto 89px ${HEADER_HEIGHT}px`,
        gap: 0,
        boxSizing: "border-box",
        minHeight: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        backgroundColor: colors.grey2,
      }}
    >
      <Box
        sx={{
          px: 2,
          mt: 5,
          overflowX: "hidden",
          overflowY: "auto",
          minHeight: `calc(100vh - ${HEADER_HEIGHT}px - 200px)`,
          maxHeight: `calc(100vh - ${HEADER_HEIGHT}px - 200px)`,
          minWidth: `${drawerWidth}px`,
          maxWidth: `${drawerWidth}px`,
        }}
      >
        <OrganizationSelector navigateToRoot={navigateToRoot} />
        <List
          size="sm"
          sx={{
            "--List-item-radius": "8px",
            "--ListItemDecorator-size": "50px",
            "--List-nestedInsetStart": "25px",
            mt: 2,
            '& [role="button"]': {
              borderRadius: "5px",
            },
          }}
        >
          {selectedOrganizationId && (
            <>
              {deployments.length > 0 && (
                <>
                  <ListSubheader>Dashboards</ListSubheader>
                  {deployments.map((deployment) => (
                    <ListItemButton
                      key={deployment.id}
                      selected={
                        ["dashboard_with_deployment"].includes(
                          currentPathName || ""
                        ) && deploymentIdInPath === deployment.id
                      }
                      onClick={() => navigateToDashboard(deployment.id)}
                    >
                      <ListItemDecorator>
                        <DashboardIcon />
                      </ListItemDecorator>
                      <ListItemContent>
                        {deployment.name || deployment.id} Dashboard
                      </ListItemContent>
                    </ListItemButton>
                  ))}
                </>
              )}
              <ListSubheader>Environment Setup</ListSubheader>
              {deployments.map((deployment) => (
                <ListItemButton
                  key={deployment.id}
                  selected={
                    ["deployment_steps_with_step"].includes(
                      currentPathName || ""
                    ) && deploymentIdInPath === deployment.id
                  }
                  onClick={() => navigateToDeploymentStatus(deployment.id)}
                >
                  <ListItemDecorator>
                    <Security />
                  </ListItemDecorator>
                  <ListItemContent>
                    {deployment.name || deployment.id} Environment
                  </ListItemContent>
                </ListItemButton>
              ))}
              <ListItemButton
                selected={["deployment_steps"].includes(currentPathName || "")}
                onClick={navigateToAddDeployment}
              >
                <ListItemDecorator>
                  <Add />
                </ListItemDecorator>
                <ListItemContent>Create a New Environment</ListItemContent>
              </ListItemButton>

              <ListSubheader>Service Setup</ListSubheader>
              {serviceStacks.map((stack) => (
                <ListItemButton
                  key={stack.id}
                  selected={
                    ["service_statuses_with_stack"].includes(
                      currentPathName || ""
                    ) && serviceIdInPath === stack.id
                  }
                  onClick={() => navigateToServiceStatus(stack.id)}
                >
                  <ListItemDecorator>
                    <MiscellaneousServicesIcon />
                  </ListItemDecorator>
                  <ListItemContent>
                    {stack.name || stack.id} Service
                  </ListItemContent>
                </ListItemButton>
              ))}
              <ListItemButton
                selected={["service_statuses"].includes(currentPathName || "")}
                onClick={navigateToAddService}
              >
                <ListItemDecorator>
                  <Add />
                </ListItemDecorator>
                <ListItemContent>Create a New Service</ListItemContent>
              </ListItemButton>
            </>
          )}

          {role === "ROOT" && (
            <>
              {/* <ListDivider inset="gutter" sx={{ my: 2 }} /> */}
              <ListSubheader>ROOT Zone</ListSubheader>

              <ListItemButton
                selected={[
                  "organizations",
                  "organizations_with_organization",
                ].includes(currentPathName || "")}
                onClick={navigateToOrganizations}
              >
                <ListItemDecorator>
                  <Business />
                </ListItemDecorator>
                <ListItemContent>Organizations</ListItemContent>
              </ListItemButton>
            </>
          )}
        </List>
      </Box>

      <Box
        sx={{
          px: 2,
          minHeight: "89px", // 100px + 13px divider + add if more needed
          maxHeight: "89px",
          minWidth: `${drawerWidth}px`,
          maxWidth: `${drawerWidth}px`,
          overflow: "hidden",
        }}
      >
        <List
          size="sm"
          sx={{
            "--List-item-radius": "8px",
            "--ListItemDecorator-size": "50px",
            mt: 2,
            '& [role="button"]': {
              borderRadius: "5px",
            },
          }}
        >
          <ListItemButton
            selected={["profile"].includes(currentPathName || "")}
            onClick={navigateToProfile}
          >
            <ListItemDecorator sx={{ alignSelf: "flex-start" }}>
              <Avatar
                color="primary"
                size="md"
                {...(picture && { src: picture })}
              />
            </ListItemDecorator>
            <ListItemContent>
              {loading ? (
                <LinearProgress size="lg" />
              ) : (
                <>
                  <Typography level="body-sm" noWrap>
                    {userName}
                  </Typography>
                  <Typography level="body-xs" noWrap>
                    {email}
                  </Typography>
                </>
              )}
            </ListItemContent>
            <KeyboardArrowRight />
          </ListItemButton>
        </List>
      </Box>
      <Box
        sx={{
          px: 2,
          minHeight: HEADER_HEIGHT,
          maxHeight: HEADER_HEIGHT,
          minWidth: `${drawerWidth}px`,
          maxWidth: `${drawerWidth}px`,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          alignContent: "stretch",
          justifyContent: "center",
          borderTop: `1px solid ${colors.grey3}`,
          backgroundColor: colors.grey2,
        }}
      >
        <LogoText />
      </Box>
    </Box>
  );
};
