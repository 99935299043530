import GitHubIcon from "@mui/icons-material/GitHub";
import Alert from "@mui/joy/Alert";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Grid from "@mui/joy/Grid";
import { useEffect, useRef, useState } from "react";
import {
  Deployment,
  GitConnection,
  ServiceStack,
  StackStatus,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import GitLabIcon from "../../../components/logos/gitlab-logo-500.svg";
import BitBucketIcon from "../../../components/logos/bitbucket-logo.svg";
import { PageLoading } from "../../../components/page_loading";
import Link from "@mui/joy/Link";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import Switch from "@mui/joy/Switch";

export const DeployEcsServiceComponent = (props: {
  loading: boolean;
  stack: ServiceStack;
  deployment: Deployment | null;
  stackStatus: StackStatus | null;
  gitConnectionArns: GitConnection[];
  queryError: string[];
  removeQueryError: (i: number) => void;
  deployEcsService: (
    instanceCount: number,
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    runtimeJson: string,
    buildtimeJson: string,
    ecsTaskCpu: number,
    ecsTaskRam: number,
    servicePort: number,
    healthCheckPath: string,
    healthCheckPort: number,
    logRetentionDays: number,
    alarmsEnabled: boolean,
    apiKeyRequired: boolean,
    iamAuthRequired: boolean,
    cicdManualApproval: boolean
  ) => void;
  refetchStackStatus: () => void;
}) => {
  const {
    loading,
    stack,
    deployment,
    stackStatus,
    gitConnectionArns,
    queryError,
    removeQueryError,
    deployEcsService,
    refetchStackStatus,
  } = props;
  const [instanceCount, setInstanceCount] = useState<number>(0);
  const [gitConnectionArn, setGitConnectionArn] = useState<string>("");
  const [gitRepositoryId, setGitRepositoryId] = useState<string>("");
  const [gitBranch, setGitBranch] = useState<string>("");
  const [dockerfilePath, setDockerfilePath] = useState<string>("");
  const [buildspecPath, setBuildspecPath] = useState<string>("");
  const [runtimeJson, setRuntimeJson] = useState<string>("");
  const [buildtimeJson, setBuildtimeJson] = useState<string>("");
  const [ecsTaskCpu, setEcsTaskCpu] = useState<number>(256);
  const [ecsTaskRam, setEcsTaskRam] = useState<number>(512);
  const [servicePort, setServicePort] = useState<number>(80);
  const [healthCheckPath, setHealthCheckPath] =
    useState<string>("/health_check");
  const [healthCheckPort, setHealthCheckPort] = useState<number>(80);
  const [logRetentionDays, setLogRetentionDays] = useState<number>(365);
  const [alarmsEnabled, setAlarmsEnabled] = useState<boolean>(true);
  const [apiKeyRequired, setApiKeyRequired] = useState<boolean>(true);
  const [iamAuthRequired, setIamAuthRequired] = useState<boolean>(true);
  const [cicdManualApproval, setCicdManualApproval] = useState<boolean>(true);

  useEffect(() => {
    if (stackStatus !== null && (stackStatus.parametersJSON || null) !== null) {
      try {
        const params = JSON.parse(stackStatus.parametersJSON!);
        setInstanceCount(
          params.InstanceCount !== undefined
            ? parseInt(params.InstanceCount)
            : 0
        );
        setGitConnectionArn(params.GitConnectionArn || "");
        setGitRepositoryId(params.GitRepositoryId || "");
        setGitBranch(params.GitBranch || "");
        setDockerfilePath(params.DockerfilePath || "");
        setBuildspecPath(params.BuildspecPath || "");
        setRuntimeJson(params.RuntimeJson || "");
        setBuildtimeJson(params.BuildEnvVarsJson || "");
        setEcsTaskCpu(
          params.EcsTaskCPU !== undefined ? parseInt(params.EcsTaskCPU) : 256
        );
        setEcsTaskRam(
          params.EcsTaskRAM !== undefined ? parseInt(params.EcsTaskRAM) : 512
        );
        setServicePort(parseInt(params.ServicePort) || 80);
        setHealthCheckPath(params.HealthCheckPath || "/health_check");
        setLogRetentionDays(
          params.LogRetentionDays !== undefined
            ? parseInt(params.LogRetentionDays)
            : 365
        );
        setHealthCheckPort(parseInt(params.HealthCheckPort) || 80);
        setAlarmsEnabled(params.AlarmsEnabled === "True");
        setApiKeyRequired(params.ApiKeyRequired === "True");
        setIamAuthRequired(params.IamAuthRequired === "True");
        setCicdManualApproval(params.CICDManualApproval === "True");
        // AuthUserPoolArn
        // AlertsSnsTopicRef
        // CICDManualApproval
      } catch (err) {
        console.info("Failed to parse parametersJSON");
      }
    }
  }, [stackStatus]);

  const cpuInputRef = useRef<HTMLInputElement | null>(null);
  const ramInputRef = useRef<HTMLInputElement | null>(null);
  const instanceCountInputRef = useRef<HTMLInputElement | null>(null);
  const servicePortInputRef = useRef<HTMLInputElement | null>(null);
  const logRetentionDaysInputRef = useRef<HTMLInputElement | null>(null);
  const healthCheckPortInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <PageLoading loading={loading} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "flex-start", flexGrow: 1 }}
      >
        {!loading && (stackStatus?.status || null) === null && (
          <Grid xs={12}>
            <Alert color="neutral" variant="soft" sx={{ mb: 2 }}>
              The service has not been deployed to this environment yet.
            </Alert>
          </Grid>
        )}
        {!loading &&
          stackStatus?.status !== null &&
          stackStatus?.status !== undefined && (
            <Grid xs={12}>
              <Alert
                color={
                  stackStatus?.status === "CREATE_COMPLETE" ||
                  stackStatus?.status === "UPDATE_COMPLETE"
                    ? "success"
                    : (stackStatus?.status || "").indexOf("CREATE") > -1 ||
                      (stackStatus?.status || "").indexOf("UPDATE") > -1
                    ? "primary"
                    : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                    ? "danger"
                    : "neutral"
                }
                variant="soft"
                sx={{ mb: 2 }}
                startDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ? (
                    <CircularProgress
                      size="sm"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                    />
                  ) : null
                }
                endDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") === -1 ? (
                    <Button
                      size="sm"
                      variant="soft"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                      onClick={() => {
                        refetchStackStatus();
                      }}
                    >
                      Refresh
                    </Button>
                  ) : null
                }
              >
                Deployment status: {stackStatus?.status}
                <Link
                  href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=waffle-${deployment?.id}-${stack?.id}-cfn-cicd`}
                >
                  Details
                </Link>
              </Alert>
            </Grid>
          )}
        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Source code</Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Select your git connection</FormLabel>
            <Select
              placeholder="Select Git Connection"
              value={gitConnectionArn}
              slotProps={{
                listbox: {
                  sx: {
                    maxHeight: 300,
                    overflow: "auto", // required for scrolling
                    zIndex: 1201, // Because of the floating drawer on mobile
                  },
                },
              }}
            >
              {gitConnectionArns?.map((conn, i) => (
                <Option
                  value={conn.id}
                  key={conn.id || i}
                  onClick={() => {
                    setGitConnectionArn(conn.id || "");
                  }}
                >
                  <ListItemDecorator>
                    {conn.providerType === "GitHub" ||
                    conn.providerType === "GitHubEnterpriseServer" ? (
                      <GitHubIcon />
                    ) : conn.providerType === "Bitbucket" ? (
                      <Avatar src={BitBucketIcon} />
                    ) : (
                      <Avatar src={GitLabIcon} />
                    )}
                  </ListItemDecorator>
                  {conn.name}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              Select the connection to your git provider that has the repository
              that you want to deploy
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Git Repository</FormLabel>
            <Input
              value={gitRepositoryId}
              onChange={(event) => {
                setGitRepositoryId(event.target.value);
              }}
            />
            <FormHelperText>
              The owner and name of the repo expected in the following format:
              some-user/my-repo
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormLabel>Git Branch</FormLabel>
          <Input
            value={gitBranch}
            onChange={(event) => {
              setGitBranch(event.target.value);
            }}
          />
          <FormHelperText>The branch in the repo to deploy</FormHelperText>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Dockerfile path</FormLabel>
            <Input
              value={dockerfilePath}
              onChange={(event) => {
                setDockerfilePath(event.target.value);
              }}
            />
            <FormHelperText>
              The relative path in the repo to the Dockerfile. Leave this field
              empty if your prefer to specify a buildspec.yml file instead, see
              next field. For example: {stack.id}/Dockerfile
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Buildpsec path (optional)</FormLabel>
            <Input
              value={buildspecPath}
              onChange={(event) => {
                setBuildspecPath(event.target.value);
              }}
            />
            <FormHelperText>
              Instead of providing a Dockerfile, you can specify a buildspec.yml
              file, to give detailed build instructions to the CICD pipeline's
              build phase. The path in the repo to the buildspec.yml file with
              build instructrions. The path has to include the filename as well.
              For example: {stack.id}/buildspec.yml
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Container settings</Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>CPU per instance</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: cpuInputRef,
                  min: 256,
                  max: 4092,
                  step: 256,
                },
              }}
              value={ecsTaskCpu}
              endDecorator={<Typography fontSize="xs">CPU</Typography>}
              onChange={(event) => {
                setEcsTaskCpu(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              Amount of CPU capcity available for each container. 1024 refers to
              a single CPU.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>RAM per instance</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: ramInputRef,
                  min: 512,
                  max: 3072,
                  step: 512,
                },
              }}
              value={ecsTaskRam}
              endDecorator={<Typography fontSize="xs">MB</Typography>}
              onChange={(event) => {
                setEcsTaskRam(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              Amount of RAM available for each container, in MB.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Service port</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: servicePortInputRef,
                  min: 0,
                  max: 65535,
                  step: 1,
                },
              }}
              value={servicePort}
              onChange={(event) => {
                setServicePort(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              The port, which is exposed on the Docker container and accepts
              HTTP requests.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Number of instances</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: instanceCountInputRef,
                  min: 0,
                  step: 1,
                },
              }}
              value={instanceCount}
              onChange={(event) => {
                setInstanceCount(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              Number of continuously running instances. Recommended to deploy
              first with 0, to have the CICD and all else set up, and only then
              to increase the instance count to the desired number
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Authentication</Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>API Key Authentication</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={apiKeyRequired}
                  onChange={(event) => setApiKeyRequired(event.target.checked)}
                />
              }
            >
              {apiKeyRequired ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Enables this specific service to be accessed with API Key
              authentication. This is intended for serving backend services
              hosted at 3rd parties.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>AWS IAM Authentication</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={iamAuthRequired}
                  onChange={(event) => setIamAuthRequired(event.target.checked)}
                />
              }
            >
              {iamAuthRequired ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Enables this specific service to be accessed with AWS IAM
              authentication. This is intended for serving frontend
              applications.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Health Checking</Typography>
          <Typography level="body-sm">
            Health checking is optional. You can specifiy an url path that
            responds with HTTP 200 OK to HTTP GET requests. There's an automated
            pulse-check every minute. If there's no answer from the specified
            url path or the response is not 2xx then the container is
            automatically killed and another is deployed.
          </Typography>
          <Typography level="body-sm">
            Heads-up: if you turn health check on, while its not handled by your
            container then it can get the CICD pipeline stuck for up to a few
            hours. The CICD only considers the deployment successful if the
            health-check passes for a few times in a raw. And if it fails then
            it retires to deploy the container several times. Even if the
            health-checks are successul, it increases the required CICD
            deployment time buy about 3 minutes.
          </Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Health-check path</FormLabel>
            <Input
              value={healthCheckPath}
              onChange={(event) => {
                setHealthCheckPath(event.target.value);
              }}
            />
            <FormHelperText>
              The URL path that responds with 2xx to HTTP GET requests
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Health-check port</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: healthCheckPortInputRef,
                  min: 0,
                  max: 65535,
                  step: 1,
                },
              }}
              value={healthCheckPort}
              onChange={(event) => {
                setHealthCheckPort(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              The port where the health-check is available. (Typically same as
              the service-port.)
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Compliance details</Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Log retention</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: logRetentionDaysInputRef,
                  min: 0,
                  max: 365,
                  step: 1,
                },
              }}
              value={logRetentionDays}
              endDecorator={<Typography fontSize="xs">days</Typography>}
              onChange={(event) => {
                setLogRetentionDays(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              How long logs are retained in AWS CloudWatch
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Alarms enabled by default</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={alarmsEnabled}
                  onChange={(event) => setAlarmsEnabled(event.target.checked)}
                />
              }
            >
              {alarmsEnabled ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Automated alarms for being over a RAM and CPU threshold,
              experiencing a health-check blackout, or HTTP 5xx errors from the
              load balancer.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Manual CICD Approval</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={cicdManualApproval}
                  onChange={(event) =>
                    setCicdManualApproval(event.target.checked)
                  }
                />
              }
            >
              {cicdManualApproval ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              If enabled, there will be a step required in the CICD to manually
              approve every deployment.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Advanced settings</Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Run-time JSON</FormLabel>
            <Input
              value={runtimeJson}
              onChange={(event) => {
                setRuntimeJson(event.target.value);
              }}
              endDecorator={<Typography fontSize="xs">JSON</Typography>}
            />
            <FormHelperText>
              An optional JSON string that you can access from the runnning
              containers as an environmental variable. Unsure? Leave it empty.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Build-time JSON</FormLabel>
            <Input
              value={buildtimeJson}
              onChange={(event) => {
                setBuildtimeJson(event.target.value);
              }}
              endDecorator={<Typography fontSize="xs">JSON</Typography>}
            />
            <FormHelperText>
              An optional JSON string that you can send access when building the
              container as an environmental variable. Unsure? Leave it empty.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <Button
            onClick={() =>
              deployEcsService(
                instanceCount,
                gitConnectionArn,
                gitRepositoryId,
                gitBranch,
                buildspecPath,
                runtimeJson,
                buildtimeJson,
                ecsTaskCpu,
                ecsTaskRam,
                servicePort,
                healthCheckPath,
                healthCheckPort,
                logRetentionDays,
                alarmsEnabled,
                apiKeyRequired,
                iamAuthRequired,
                cicdManualApproval
              )
            }
            disabled={
              loading || (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1
            }
          >
            {(stackStatus?.status || null) === null ? "Deploy" : "Update"}{" "}
            Service
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
