import { useContext, useState } from "react";
import {
  useGetMyWaffleCfnTemplateQuery,
  useTestMyWaffleRoleLazyQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { CfnRoleComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const CfnRoleContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
  navigateWhenFinished: () => void;
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const { data: getCfnData, loading: getCfnLoading } =
    useGetMyWaffleCfnTemplateQuery({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const downloadCfnJsonBlob = (): void => {
    const jsonContent = getCfnData?.getMyWaffleCfnTemplate || null;
    if (jsonContent === null) {
      return;
    }
    const blob = new Blob([jsonContent], {
      type: "application/json;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "waffle-role-cloudformation.json");
    pom.click();
    pom.remove();
  };

  const [testMyWaffleRole, { loading: testMyWaffleRoleLoading }] =
    useTestMyWaffleRoleLazyQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const [connectionWorks, setConnectionWorks] = useState<boolean | null>(null);
  const [connectionDetails, setConnectionDetails] = useState<string | null>(
    null
  );

  const checkConnection = () => {
    testMyWaffleRole({
      variables: {
        deploymentId,
      },
      onCompleted: (data) => {
        const passed = data.testMyWaffleRole?.passed;
        setConnectionWorks(passed === undefined ? null : passed);
        const details = data.testMyWaffleRole?.details;
        setConnectionDetails(details === undefined ? null : details);
      },
    });
  };

  return (
    <CfnRoleComponent
      loading={getCfnLoading || testMyWaffleRoleLoading}
      downloadCfnJsonBlob={downloadCfnJsonBlob}
      connectionWorks={connectionWorks}
      connectionDetails={connectionDetails}
      checkConnection={checkConnection}
      done={connectionWorks || false}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
    />
  );
};
