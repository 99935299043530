import { ResourcesConfig } from "aws-amplify";

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      //  mandatorySignIn: true,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || "",
      identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || "",
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID || "",
    },
  },
};
