export const apiConfig = {
  protocol: process.env.REACT_APP_BACKEND_PROTOCOL,
  host: process.env.REACT_APP_BACKEND_HOST,
  path: process.env.REACT_APP_BACKEND_PATH,
  apiUrl: `${process.env.REACT_APP_BACKEND_PROTOCOL}${
    process.env.REACT_APP_BACKEND_HOST
  }${process.env.REACT_APP_BACKEND_PATH !== "" ? "/" : ""}${
    process.env.REACT_APP_BACKEND_PATH
  }`,
  graphqlPath: "/backend_gateway/graphql",
  region: process.env.REACT_APP_AWS_PROJECT_REGION,
};
