import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReplayIcon from "@mui/icons-material/Replay";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { PageLoading } from "../../../components/page_loading";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { useRef, useState } from "react";
import Sheet from "@mui/joy/Sheet";

export const DeploymentStepsVpcComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  queryError: string[];
  removeQueryError: (i: number) => void;
  checkStackStatus: () => void;
  stackId: string | null;
  stackStatus: string | null;
  parametersJson: string | null;
  deployStack: (subnetIp: number | null) => void;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  disableDeployButton: boolean;
}) => {
  const {
    loading,
    deployment,
    queryError,
    removeQueryError,
    checkStackStatus,
    stackId,
    stackStatus,
    // parametersJson,
    deployStack,
    navigateToNext,
    navigateToPrevious,
    disableDeployButton,
  } = props;
  const [subnetIp, setSubnetIp] = useState<number | null>(null);
  const subnetIpInputRef = useRef<HTMLInputElement | null>(null);
  // useEffect(() => {
  //   if (parametersJson !== null) {
  //     try {
  //       setSubnetIp(
  //         (JSON.parse(parametersJson) as any).AllowAdminCreateUserOnly ===
  //           "True"
  //       );
  //     } catch (err) {}
  //   }
  // }, [parametersJson]);
  const stackIdCreated = stackId || `waffle-${deployment?.id}-vpc`;
  return (
    <>
      <PageLoading loading={loading || disableDeployButton} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            Virtual Private Cloud
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about creating a private network in your AWS account.
            Services that support being deployed to private networks - like
            dockerized services, serverless functions, SQL databases, etc - are
            going to be deployed into it to prevent any uncontrolled public
            access. Besides the components required for the network to function,
            in- and outbound network traffic rules are created. Also connections
            with some AWS internal services are established that are required
            for private setups to work, like accessing your private docker image
            repository (ECR) or your Dynamo DB tables.
          </Typography>
        </Grid>
        {(stackId !== null || stackStatus !== null) && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              You already have a VPC stack
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              The status of the deployment is:{" "}
              <Typography variant="outlined" color="primary" noWrap>
                {stackStatus}
              </Typography>{" "}
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Deployment details here:{" "}
              <Link
                href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/events?filteringText=&filteringStatus=active&viewNested=true&stackId=${stackIdCreated}`}
                // href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=${stackId}`}
              >
                CloudFormation stacks
              </Link>
              .
            </Typography>
            <Button
              size="sm"
              variant="soft"
              disabled={loading}
              onClick={() => checkStackStatus()}
              sx={{ mt: 2 }}
              startDecorator={<ReplayIcon />}
            >
              Update status
            </Button>
          </Grid>
        )}
        {stackId === null && stackStatus === null && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              Create your VPC Stack
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Waffle uses CloudFormation to set the VPC up in your AWS account.
            </Typography>
            <Sheet variant="outlined" sx={{ borderRadius: 6, my: 2 }}>
              <AccordionGroup>
                <Accordion>
                  <AccordionSummary>
                    Advanced Settings (Optional)
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl sx={{ maxWidth: 350, pt: 2 }}>
                      <FormLabel>Subnet IP</FormLabel>
                      <Input
                        type="number"
                        slotProps={{
                          input: {
                            ref: subnetIpInputRef,
                            min: 1,
                            max: 254,
                            step: 1,
                          },
                        }}
                        value={subnetIp || ""}
                        disabled={loading}
                        onChange={(event) => {
                          setSubnetIp(parseInt(event.target.value));
                        }}
                      />
                      <FormHelperText>
                        Valid values are 1 to 254. Unsure? Leave it empty.
                      </FormHelperText>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            </Sheet>
            <Button
              size="lg"
              disabled={loading || disableDeployButton}
              onClick={() => deployStack(subnetIp)}
              sx={{ mt: 2 }}
            >
              Create VPC Stack
            </Button>
          </Grid>
        )}

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={loading || stackId === null}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
