import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useGetMyCertificateQuery,
  useGetMyDeploymentsQuery,
  useRequestMyCertificateMutation,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeploymentStepsCertificateComponent } from "./component";

export const DeploymentStepsCertificateContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const [requestMyCertificate, { loading: requestMyCertificateLoading }] =
    useRequestMyCertificateMutation({
      client: bgwService.getClient(),
    });

  const {
    data: getMyCertificateData,
    loading: getMyCertificateLoading,
    refetch: getMyCertificateRefetch,
  } = useGetMyCertificateQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  useEffect(() => {
    if ((getMyDeploymentsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentsError!.message]);
    }
  }, [getMyDeploymentsError]);

  const [disableRequestButton, setDisableRequestButton] =
    useState<boolean>(false);

  const requestCertificate = () => {
    setDisableRequestButton(true);
    requestMyCertificate({
      variables: {
        deploymentId,
      },
      onCompleted: (data) => {
        setTimeout(async () => {
          await getMyCertificateRefetch();
          setDisableRequestButton(false);
        }, 3000);
      },
      onError: (error) => {
        setQueryError((e) => [...e, `Failed request certificate.`]);
        setDisableRequestButton(false);
        console.error(error);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        getMyCertificateData?.getMyCertificate?.status === "PENDING_VALIDATION"
      ) {
        getMyCertificateRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyCertificateData, getMyCertificateRefetch]);

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  return (
    <DeploymentStepsCertificateComponent
      loading={
        getMyDeploymentsLoading ||
        requestMyCertificateLoading ||
        getMyCertificateLoading
      }
      deployment={deployment || null}
      queryError={queryError}
      removeQueryError={removeQueryError}
      requestCertificate={requestCertificate}
      certificateArn={getMyCertificateData?.getMyCertificate?.arn || null}
      certificateStatus={getMyCertificateData?.getMyCertificate?.status || null}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      disableRequestButton={disableRequestButton}
    />
  );
};
