import { gql } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useCreateMyGitConnectionMutation,
  useGetMyGitConnectionsQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeploymentStepsGitConnectionComponent } from "./component";

export const DeploymentStepsGitConnectionContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyGitConnectionsData,
    loading: getMyGitConnectionsLoading,
    error: getMyGitConnectionsError,
    refetch: getMyGitConnectionsRefetch,
  } = useGetMyGitConnectionsQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });
  const [queryError, setQueryError] = useState<string[]>([]);

  useEffect(() => {
    if ((getMyGitConnectionsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyGitConnectionsError!.message]);
    }
  }, [getMyGitConnectionsError]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const [
    createMyGitConnectionMutation,
    { loading: createMyGitConnectionLoading },
  ] = useCreateMyGitConnectionMutation({
    client: bgwService.getClient(),
  });

  const createGitConnection = (name: string, providerType: string) => {
    createMyGitConnectionMutation({
      variables: {
        gitConnectionInput: {
          deploymentId,
          name,
          providerType,
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getMyGitConnections(existingGitConnections = []) {
              const newGitConnectionsRef = cache.writeFragment({
                data: data?.createMyGitConnection,
                fragment: gql`
                  fragment NewGitConnection on GitConnection {
                    id
                    name
                    connectionStatus
                    ownerAccountId
                    providerType
                    pendingConnectionLink
                  }
                `,
              });
              return [...existingGitConnections, newGitConnectionsRef];
            },
          },
        });
      },
      refetchQueries: ["GetMyGitConnections"],
      onCompleted: (data) => {
        if (data?.createMyGitConnection !== undefined) {
          // ...
        }
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to add ${name} @ ${providerType}.`,
        ]);
        console.error(error);
      },
    });
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (
        getMyGitConnectionsData?.getMyGitConnections
          ?.map((c) => c.connectionStatus)
          .includes("PENDING")
      ) {
        getMyGitConnectionsRefetch();
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [getMyGitConnectionsData, getMyGitConnectionsRefetch]);

  return (
    <DeploymentStepsGitConnectionComponent
      loading={getMyGitConnectionsLoading || createMyGitConnectionLoading}
      queryError={queryError}
      removeQueryError={removeQueryError}
      createGitConnection={createGitConnection}
      connections={getMyGitConnectionsData?.getMyGitConnections || []}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
    />
  );
};
