import Box from "@mui/joy/Box";
import { ReactNode } from "react";
import { colors } from "../../colors";
import { HEADER_HEIGHT } from "../constants";

export const FloatingHeader = (props: {
  drawerWidth: number;
  children?: ReactNode;
  sx?: any;
}) => {
  const { drawerWidth, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={[
        {
          p: 1,
          m: 0,
          boxSizing: "border-box",
          gap: 2,
          position: "fixed",
          top: 0,
          right: 0,
          height: HEADER_HEIGHT,
          left: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: drawerWidth,
          },
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
          },
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          background: `rgba(246, 246, 246, 0.6)`,
          boxShadow: "none",
          backdropFilter: "blur(8px)",
          borderBottom: `1px solid ${colors.grey2}`,
          zIndex: 1100,
        },
        {
          "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1099,
            background: `linear-gradient(to top, transparent, ${colors.grey1})`,
          },
          "&:after": {
            content: '""',
            position: "absolute",
            top: HEADER_HEIGHT,
            right: 0,
            height: "1px",
            left: 0,
            zIndex: 1101,
            background: `rgba(0, 0, 0, 0.04)`,
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};
