import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GitHubIcon from "@mui/icons-material/GitHub";
import Button from "@mui/joy/Button";
import Avatar from "@mui/joy/Avatar";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { useState } from "react";
import { GitConnection } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import GitLabIcon from "../../../components/logos/gitlab-logo-500.svg";
import BitBucketIcon from "../../../components/logos/bitbucket-logo.svg";
import { PageLoading } from "../../../components/page_loading";

export const GitConnectionComponent = (props: {
  loading: boolean;
  createGitConnection: (name: string, providerType: string) => void;
  connections: GitConnection[];
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
}) => {
  const {
    loading,
    createGitConnection,
    connections,
    navigateToNext,
    navigateToPrevious,
  } = props;
  const [createConnectionName, setCreateConnectionName] = useState<string>("");
  const [createConnectionProvider, setCreateConnectionProvider] =
    useState<string>("GitHub");
  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography level="h2" sx={{ pb: 2 }}>
            Establishing a Git Connection
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about connecting your AWS account to your git
            repository. We are using AWS CodeStar for that, with maintains
            connections with different git providers like GitHub or GitLab.
          </Typography>
        </Grid>
        {connections.length > 0 && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              You already have connections
            </Typography>
            <List>
              {connections.map((conn, i) => (
                <ListItem key={i}>
                  <ListItemButton
                    component="a"
                    href={conn.pendingConnectionLink || ""}
                  >
                    <ListItemDecorator>
                      {conn.providerType === "GitHub" ||
                      conn.providerType === "GitHubEnterpriseServer" ? (
                        <GitHubIcon />
                      ) : conn.providerType === "Bitbucket" ? (
                        <Avatar src={BitBucketIcon} />
                      ) : (
                        <Avatar src={GitLabIcon} />
                      )}
                    </ListItemDecorator>
                    <ListItemContent>
                      <Typography level="title-sm">{conn.name}</Typography>
                      <Typography level="body-sm" noWrap>
                        <Typography
                          variant="soft"
                          color={
                            conn.connectionStatus === "AVAILABLE"
                              ? "success"
                              : conn.connectionStatus === "ERROR"
                              ? "danger"
                              : "warning"
                          }
                          fontSize="xs"
                          sx={{ borderRadius: "12px", mx: "4px", px: "8px" }}
                        >
                          {conn.connectionStatus}
                        </Typography>
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Typography level="body-md" sx={{ py: 1 }}>
              Click on the list items above to approve{" "}
              <Typography
                variant="soft"
                color="warning"
                fontSize="xs"
                sx={{ borderRadius: "12px", mx: "4px", px: "8px" }}
              >
                PENDING
              </Typography>{" "}
              connections, and to manage them directly in the AWS console.
            </Typography>
          </Grid>
        )}
        <Grid xs={12}>
          <Typography level="h3" sx={{ py: 2 }}>
            Create {connections.length > 0 ? "another" : "a new"} connection
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            You need to have at least one connection that you can use in this
            environment. You can re-use existing connections if there are any,
            or create new ones. You can create additional connections later too.
          </Typography>
          <FormControl sx={{ maxWidth: 350, pt: 2 }}>
            <FormLabel>Connection name</FormLabel>
            <Input
              placeholder="eg. my repo"
              value={createConnectionName}
              onChange={(event) => setCreateConnectionName(event.target.value)}
              required
            />
          </FormControl>
          <FormControl sx={{ maxWidth: 350, pt: 2 }}>
            <FormLabel>Provider</FormLabel>
            <Select
              value={createConnectionProvider}
              onChange={(
                event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                newValue && setCreateConnectionProvider(newValue!);
              }}
            >
              <Option value="Bitbucket">
                <ListItemDecorator>
                  <Avatar size="sm" src={BitBucketIcon} />
                </ListItemDecorator>
                Bitbucket
              </Option>
              <Option value="GitHub">
                <ListItemDecorator>
                  <Avatar size="sm">
                    <GitHubIcon />
                  </Avatar>
                </ListItemDecorator>
                GitHub
              </Option>
              <Option value="GitHubEnterpriseServer">
                <ListItemDecorator>
                  <Avatar size="sm">
                    <GitHubIcon />
                  </Avatar>
                </ListItemDecorator>
                GitHubEnterpriseServer
              </Option>
              <Option value="GitLab">
                <ListItemDecorator>
                  <Avatar size="sm" src={GitLabIcon} />
                </ListItemDecorator>
                GitLab
              </Option>
              <Option value="GitLabSelfManaged">
                <ListItemDecorator>
                  <Avatar size="sm" src={GitLabIcon} />
                </ListItemDecorator>
                GitLabSelfManaged
              </Option>
            </Select>
          </FormControl>
          <Button
            size="lg"
            disabled={loading || createConnectionName === ""}
            onClick={() =>
              createGitConnection(
                createConnectionName,
                createConnectionProvider
              )
            }
            sx={{ mt: 2 }}
          >
            Create Connection
          </Button>
        </Grid>
        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={connections.length === 0}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
