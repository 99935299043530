import { ReactNode, useContext, useMemo } from "react";
import { useGetMyOrganizationQuery } from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { BgwContext } from "../../contexts/backend_gateway/context";

export const OnboarderContainer = (props: {
  onboardingComponent: ReactNode;
  children: ReactNode;
}) => {
  const { onboardingComponent, children } = props;
  const { bgwService } = useContext(BgwContext);
  const { data: getMyOrganization, loading: getMyOrganizationLoading } =
    useGetMyOrganizationQuery({
      client: bgwService.getClient(),
    });
  const amIOnboarded = useMemo(
    () => getMyOrganization?.getMyOrganization?.onboardingComplete,
    [getMyOrganization]
  );

  if (getMyOrganizationLoading) {
    return null;
  }

  if (amIOnboarded || true) {
    return children;
  }

  return onboardingComponent;
};
