import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";
import { PageLoading } from "../../components/page_loading";
import { colors } from "../../layouts/drawer_layout/colors";
import { Deployment } from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { LeanLayout } from "./layout";

export const OnboardingComponent = (props: {
  loading: boolean;
  deploymentId: string;
  deployment: Deployment | null;
  stepComponent: ReactNode | null;
  stepperComponent: ReactNode | null;
}) => {
  const { loading, deployment, stepComponent, stepperComponent } = props;
  return (
    <LeanLayout>
      <Typography level="body-sm" sx={{ pb: 2 }}>
        Deploying to AWS account{" "}
        <Typography variant="soft" fontSize="xs">
          {deployment?.accountId || ""}
        </Typography>{" "}
        @{" "}
        <Typography variant="soft" fontSize="xs">
          {deployment?.awsRegion || ""}
        </Typography>
      </Typography>
      <PageLoading loading={loading} />
      <Grid
        container
        spacing={2}
        sx={{
          flexGrow: 1,
          borderTop: `1px solid ${colors.grey4}`,
          pt: 4,
          mt: 1,
        }}
      >
        <Grid xs={12} sm={12} sx={{ mb: 4 }}>
          {stepperComponent}
        </Grid>
        <Grid xs={12} sm={12}>
          {stepComponent}
        </Grid>
      </Grid>
    </LeanLayout>
  );
};
