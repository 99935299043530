import { createContext } from "react";
import { AlertsType } from "./type";

const defaultValue: AlertsType = {
  alerts: [],
  addAlert: () => {},
  removeAlert: () => {},
};

export const AlertsContext = createContext(defaultValue);
