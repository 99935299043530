import { useContext, useEffect, useState } from "react";
import {
  Deployment,
  ServiceStack,
  useDeployMyEcsFargateStackMutation,
  useGetMyEcsServiceStackStatusQuery,
  useGetMyGitConnectionsQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeployEcsServiceComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";

export const DeployEcsServiceContainer = (props: {
  deployment: Deployment;
  stack: ServiceStack;
}) => {
  const { deployment, stack } = props;
  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const { bgwService } = useContext(BgwContext);
  const {
    data: getMyGitConnectionsData,
    loading: getMyGitConnectionsLoading,
    error: getMyGitConnectionsError,
  } = useGetMyGitConnectionsQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
    },
  });

  useEffect(() => {
    if ((getMyGitConnectionsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyGitConnectionsError!.message]);
    }
  }, [getMyGitConnectionsError]);

  const {
    data: getMyEcsServiceStackStatusData,
    loading: getMyEcsServiceStackStatusLoading,
    error: getMyEcsServiceStackStatusError,
    refetch: getMyEcsServiceStackStatusRefetch,
  } = useGetMyEcsServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
      pipelineId: stack.id,
    },
  });

  useEffect(() => {
    if ((getMyEcsServiceStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyEcsServiceStackStatusError!.message]);
    }
  }, [getMyEcsServiceStackStatusError]);

  const [
    deployMyEcsFargateStackMutation,
    { loading: deployMyEcsFargateStackLoading },
  ] = useDeployMyEcsFargateStackMutation({
    client: bgwService.getClient(),
  });

  const deployEcsService = (
    instanceCount: number,
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    runtimeJson: string,
    buildtimeJson: string,
    ecsTaskCpu: number,
    ecsTaskRam: number,
    servicePort: number,
    healthCheckPath: string,
    healthCheckPort: number,
    logRetentionDays: number,
    alarmsEnabled: boolean,
    apiKeyRequired: boolean,
    iamAuthRequired: boolean,
    cicdManualApproval: boolean
  ) => {
    deployMyEcsFargateStackMutation({
      variables: {
        stackDeploymentInput: {
          id: stack.id,
          deploymentId: deployment.id,
          instanceCount,
          gitConnectionArn,
          gitRepositoryId,
          gitBranch,
          buildspecPath,
          runtimeJson,
          buildtimeJson,
          ecsTaskCpu,
          ecsTaskRam,
          servicePort,
          healthCheckPath,
          healthCheckPort,
          logRetentionDays,
          alarmsEnabled,
          apiKeyRequired,
          iamAuthRequired,
          cicdManualApproval,
        },
      },
      // update(cache, { data }) {
      //   cache.modify({
      //     fields: {
      //       getMyEcsServiceStackStatus(existingnewStackStatuses = []) {
      //         const newStackStatuskRef = cache.writeFragment({
      //           data: data?.deployMyEcsFargateStack,
      //           fragment: gql`
      //             fragment NewStackStatus on StackStatus {
      //               status
      //               parametersJSON
      //             }
      //           `,
      //         });
      //         return [...existingnewStackStatuses, newStackStatuskRef];
      //       },
      //     },
      //   });
      // },
      onCompleted: (data) => {
        getMyEcsServiceStackStatusRefetch();
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to deploy stack to ${deployment.id}.`,
        ]);
        console.error(error);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyEcsServiceStackStatusData?.getMyEcsServiceStackStatus?.status ||
          ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyEcsServiceStackStatusRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyEcsServiceStackStatusData, getMyEcsServiceStackStatusRefetch]);

  return (
    <DeployEcsServiceComponent
      loading={
        getMyGitConnectionsLoading ||
        getMyEcsServiceStackStatusLoading ||
        deployMyEcsFargateStackLoading
      }
      stack={stack}
      deployment={deployment}
      stackStatus={
        getMyEcsServiceStackStatusData?.getMyEcsServiceStackStatus || null
      }
      gitConnectionArns={getMyGitConnectionsData?.getMyGitConnections || []}
      queryError={queryError}
      removeQueryError={removeQueryError}
      deployEcsService={deployEcsService}
      refetchStackStatus={() => {
        getMyEcsServiceStackStatusRefetch();
      }}
    />
  );
};
