import { PageWithRightPane } from "../../layouts/page_with_right_pane";
import { OrganizationsContainer } from "./container";

export const OrganizationsPage = (props: {}) => {
  return (
    <PageWithRightPane>
      <OrganizationsContainer />
    </PageWithRightPane>
  );
};
