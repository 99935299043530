import { ReactNode, useState } from "react";
import { AlertsContext } from "./context";
import { AlertWithType } from "./type";

const { Provider } = AlertsContext;

export const AlertsProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [alerts, setAlerts] = useState<AlertWithType[]>([]);
  const addAlert = (a: AlertWithType) => {
    setAlerts((as) => [...as, a]);
  };
  const removeAlert = (i: number) => {
    if (i < alerts.length) {
      setAlerts((as) => [...as.splice(0, i), ...as.splice(i + 1)]);
    }
  };
  const value = {
    alerts,
    addAlert,
    removeAlert,
  };
  return <Provider value={value}>{children}</Provider>;
};
