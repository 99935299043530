import { useContext, useMemo } from "react";
import { DashboardComponent } from "./component";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { AlertsContext } from "../../contexts/alerts/context";
import {
  ServiceStack,
  useGetMyDeploymentsQuery,
  useGetMyServiceStacksQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../contexts/alerts/type";

export const DashboardContainer = (props: { deploymentId: string }) => {
  const { deploymentId } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const { data: getMyDeploymentsData, loading: getMyDeploymentsLoading } =
    useGetMyDeploymentsQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const deployment = useMemo(() => {
    return getMyDeploymentsData?.getMyDeployments?.find(
      (d) => d.id === deploymentId
    );
  }, [getMyDeploymentsData, deploymentId]);

  const { data: getMyServiceStacksData, loading: getMyServiceStacksLoading } =
    useGetMyServiceStacksQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const cloudTrailLink = deployment
    ? `https://${deployment.awsRegion}.console.aws.amazon.com/cloudtrailv2/home?region=${deployment.awsRegion}#/events?ReadOnly=false`
    : null;
  const codePipelineLink = deployment
    ? `https://${deployment.awsRegion}.console.aws.amazon.com/codesuite/codepipeline/pipelines?region=${deployment.awsRegion}`
    : null;

  const secretsLink = deployment
    ? `https://${deployment.awsRegion}.console.aws.amazon.com/secretsmanager/listsecrets?region=${deployment.awsRegion}`
    : null;

  const apiGatewayHostnameParameterLink = deployment
    ? `https://${deployment.awsRegion}.console.aws.amazon.com/systems-manager/parameters/%252F${deployment.id}%252Fapi%252FhostName/description?region=${deployment.awsRegion}&tab=Table`
    : null;

  const getServiceCloudWatchLink = (service: ServiceStack) => {
    if (service.type === "ECS") {
      return deployment
        ? `https://${deployment.awsRegion}.console.aws.amazon.com/cloudwatch/home?region=${deployment.awsRegion}#logsV2:log-groups/log-group/${deployment.id}-${service.id}`
        : null;
    }
    return null;
  };

  const getServiceHostnameParameterLink = (service: ServiceStack) => {
    if (service.type === "ECS") {
      return deployment
        ? `https://${deployment.awsRegion}.console.aws.amazon.com/systems-manager/parameters/%252F${deployment.id}%252Fecs%252F${service.id}%252FalbDnsName/description?region=${deployment.awsRegion}&tab=Table`
        : null;
    }
    return null;
  };

  const getServiceApiGatewayEndpoint = (service: ServiceStack) => {
    if (service.type === "ECS") {
      return deployment
        ? `https://api.${deployment.id}.${deployment.fullDomainName}/Prod/${service.id}`
        : null;
    }
    return null;
  };

  const cloudFrontLink = deployment
    ? `https://${deployment.awsRegion}.console.aws.amazon.com/cloudfront/v4/home?region=${deployment.awsRegion}#/distributions`
    : null;

  const apiGatewayApiKeysLink = deployment
    ? `https://${deployment.awsRegion}.console.aws.amazon.com/apigateway/main/api-keys?api=unselected&region=${deployment.awsRegion}`
    : null;
  const apiGatewayUsagePlansLink = deployment
    ? `https://${deployment.awsRegion}.console.aws.amazon.com/apigateway/main/usage-plans?api=unselected&region=${deployment.awsRegion}`
    : null;

  return (
    <DashboardComponent
      loading={getMyDeploymentsLoading || getMyServiceStacksLoading}
      deploymentId={deploymentId}
      deployment={deployment || null}
      cloudTrailLink={cloudTrailLink}
      codePipelineLink={codePipelineLink}
      secretsLink={secretsLink}
      cloudFrontLink={cloudFrontLink}
      apiGatewayHostnameParameterLink={apiGatewayHostnameParameterLink}
      apiGatewayApiKeysLink={apiGatewayApiKeysLink}
      apiGatewayUsagePlansLink={apiGatewayUsagePlansLink}
      services={getMyServiceStacksData?.getMyServiceStacks || []}
      getServiceCloudWatchLink={getServiceCloudWatchLink}
      getServiceHostnameParameterLink={getServiceHostnameParameterLink}
      getServiceApiGatewayEndpoint={getServiceApiGatewayEndpoint}
    />
  );
};
