import { useContext, useEffect, useState } from "react";
import { DeploymentStepsAuthenticationComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useCreateMyAuthenticationStackMutation,
  useFindMyAuthenticationStackValuesQuery,
  useGetMyDeploymentsQuery,
  useGetMyAuthenticationStackStatusQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsAuthenticationContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const {
    data: findMyAuthenticationStackValuesData,
    loading: findMyAuthenticationStackValuesLoading,
    refetch: findMyAuthenticationStackValuesRefetch,
  } = useFindMyAuthenticationStackValuesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const {
    data: getMyAuthenticationStackStatusData,
    loading: getMyAuthenticationStackStatusLoading,
    error: getMyAuthenticationStackStatusError,
    refetch: getMyAuthenticationStackStatusRefetch,
  } = useGetMyAuthenticationStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const [
    createMyAuthenticationStack,
    { loading: createMyAuthenticationStackLoading },
  ] = useCreateMyAuthenticationStackMutation({
    client: bgwService.getClient(),
  });

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  useEffect(() => {
    if ((getMyDeploymentsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentsError!.message]);
    }
  }, [getMyDeploymentsError]);

  useEffect(() => {
    if ((getMyAuthenticationStackStatusError?.message || null) !== null) {
      setQueryError((e) => [
        ...e,
        getMyAuthenticationStackStatusError!.message,
      ]);
    }
  }, [getMyAuthenticationStackStatusError]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyAuthenticationStackStatusData?.getMyAuthenticationStackStatus
            ?.status || ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyAuthenticationStackStatusRefetch();
        findMyAuthenticationStackValuesRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    getMyAuthenticationStackStatusData,
    getMyAuthenticationStackStatusRefetch,
    findMyAuthenticationStackValuesRefetch,
  ]);

  const [disableDeployButton, setDisableDeployButton] =
    useState<boolean>(false);

  const deployStack = (allowAdminCreateUserOnly: boolean = false) => {
    setDisableDeployButton(true);
    createMyAuthenticationStack({
      variables: {
        deploymentId,
        allowAdminCreateUserOnly,
      },
      onCompleted: (data) => {
        setTimeout(checkStackStatus, 3000);
      },
      onError: (error) => {
        setQueryError((e) => [...e, `Failed deploy stack.`]);
        setDisableDeployButton(false);
        console.error(error);
      },
    });
  };

  const checkStackStatus = () => {
    getMyAuthenticationStackStatusRefetch();
    findMyAuthenticationStackValuesRefetch();
    setTimeout(() => {
      setDisableDeployButton(false);
    }, 3000);
  };

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  return (
    <DeploymentStepsAuthenticationComponent
      loading={
        getMyDeploymentsLoading ||
        findMyAuthenticationStackValuesLoading ||
        getMyAuthenticationStackStatusLoading ||
        createMyAuthenticationStackLoading
      }
      deployment={deployment || null}
      queryError={queryError}
      removeQueryError={removeQueryError}
      checkStackStatus={checkStackStatus}
      stackId={
        findMyAuthenticationStackValuesData?.findMyAuthenticationStackValues ||
        null
      }
      stackStatus={
        getMyAuthenticationStackStatusData?.getMyAuthenticationStackStatus
          ?.status || null
      }
      parametersJson={
        getMyAuthenticationStackStatusData?.getMyAuthenticationStackStatus
          ?.parametersJSON || null
      }
      deployStack={deployStack}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      disableDeployButton={disableDeployButton}
    />
  );
};
