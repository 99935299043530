import { useContext, useEffect, useState } from "react";
import { DeploymentStepsDeploymentComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useCreateMyDeploymentStackMutation,
  useFindMyDeploymentStackValuesQuery,
  useGetMyDeploymentsQuery,
  useGetMyDeploymentStackStatusQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";

export const DeploymentStepsDeploymentContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const {
    data: findMyDeploymentStackValuesData,
    loading: findMyDeploymentStackValuesLoading,
    refetch: findMyDeploymentStackValuesRefetch,
  } = useFindMyDeploymentStackValuesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const {
    data: getMyDeploymentStackStatusData,
    loading: getMyDeploymentStackStatusLoading,
    error: getMyDeploymentStackStatusError,
    refetch: getMyDeploymentStackStatusRefetch,
  } = useGetMyDeploymentStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });

  const [createMyDeploymentStack, { loading: createMyDeploymentStackLoading }] =
    useCreateMyDeploymentStackMutation({
      client: bgwService.getClient(),
    });

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  useEffect(() => {
    if ((getMyDeploymentsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentsError!.message]);
    }
  }, [getMyDeploymentsError]);

  useEffect(() => {
    if ((getMyDeploymentStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentStackStatusError!.message]);
    }
  }, [getMyDeploymentStackStatusError]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyDeploymentStackStatusData?.getMyDeploymentStackStatus?.status ||
          ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyDeploymentStackStatusRefetch();
        findMyDeploymentStackValuesRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    getMyDeploymentStackStatusData,
    getMyDeploymentStackStatusRefetch,
    findMyDeploymentStackValuesRefetch,
  ]);

  const [disableDeployButton, setDisableDeployButton] =
    useState<boolean>(false);

  const deployStack = () => {
    setDisableDeployButton(true);
    createMyDeploymentStack({
      variables: {
        deploymentId,
      },
      onCompleted: (data) => {
        setTimeout(checkStackStatus, 3000);
      },
      onError: (error) => {
        setQueryError((e) => [...e, `Failed deploy stack.`]);
        setDisableDeployButton(false);
        console.error(error);
      },
    });
  };

  const checkStackStatus = () => {
    getMyDeploymentStackStatusRefetch();
    findMyDeploymentStackValuesRefetch();
    setTimeout(() => {
      setDisableDeployButton(false);
    }, 3000);
  };

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  return (
    <DeploymentStepsDeploymentComponent
      loading={
        getMyDeploymentsLoading ||
        findMyDeploymentStackValuesLoading ||
        getMyDeploymentStackStatusLoading ||
        createMyDeploymentStackLoading
      }
      deployment={deployment || null}
      queryError={queryError}
      removeQueryError={removeQueryError}
      checkStackStatus={checkStackStatus}
      stackId={
        findMyDeploymentStackValuesData?.findMyDeploymentStackValues || null
      }
      stackStatus={
        getMyDeploymentStackStatusData?.getMyDeploymentStackStatus?.status ||
        null
      }
      deployStack={deployStack}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      disableDeployButton={disableDeployButton}
    />
  );
};
