export enum AlertType {
  NEUTRAL = "NEUTRAL",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  DANGER = "DANGER",
}

export type AlertWithType = {
  type: AlertType;
  text: string;
};

export type AlertsType = {
  alerts: AlertWithType[];
  addAlert: (a: AlertWithType) => void;
  removeAlert: (i: number) => void;
};
