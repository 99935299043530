export const ROUTES = {
  root: { path: "/" },
  dashboard_with_deployment: { path: "/dashboard/:deploymentId" },
  deployment_steps: { path: "/deployment_steps" },
  deployment_steps_with_step: {
    path: "/deployment_steps/:deploymentId/:stepId",
  },
  service_statuses: { path: "/service_statuses" },
  service_statuses_with_stack: { path: "/service_statuses/:stackId" },
  organizations: { path: "/organizations" },
  organizations_with_organization: { path: "/organizations/:organizationId" },
  profile: { path: "/profile" },
};
