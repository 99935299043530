import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";

export const GridCardWithImg = (props: {
  gridXs: number;
  gridSm: number;
  imgRatio: string;
  imgSrc: string;
  title: string;
  text: string;
}) => {
  const { gridXs, gridSm, imgRatio, imgSrc, title, text } = props;
  return (
    <Grid xs={gridXs} sm={gridSm}>
      <Card variant="outlined">
        <AspectRatio ratio={imgRatio}>
          <img src={imgSrc} alt="" />
        </AspectRatio>
        <div>
          <Typography level="title-md">{title}</Typography>
          <Typography level="body-sm">{text}</Typography>
        </div>
      </Card>
    </Grid>
  );
};
