import "@fontsource/share-tech-mono";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";
import { colors } from "../../layouts/drawer_layout/colors";

export const PageTitle = (props: { children?: ReactNode; sx?: any }) => {
  const { sx, ...rest } = props;
  return (
    <Typography
      sx={[
        {
          fontSize: {
            xs: "22px",
            sm: "35px",
          },
          fontFamily: "'Share Tech Mono'",
          // fontWeight: 800,
          color: colors.grey10,
          textTransform: "uppercase",
          letterSpacing: ".4rem",
        },
        {
          ...(sx || {}),
        },
      ]}
      {...rest}
    />
  );
};
