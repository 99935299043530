import { ReactNode, useEffect, useState } from "react";
import { BgwService } from "../../services/backend_gateway/service";
import { BgwContext } from "./context";
import { BgwType } from "./type";

const { Provider } = BgwContext;

export const BgwProvider = (props: { children: ReactNode }): JSX.Element => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    localStorage.getItem("selectedOrganizationId")
  );

  useEffect(() => {
    const bgwService = BgwService.getInstance();
    bgwService.setSelectedOrganizationId(selectedOrganizationId);
  }, [selectedOrganizationId]);

  const value: BgwType = {
    bgwService: BgwService.getInstance(),
    selectedOrganizationId,
    setSelectedOrganizationId,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
