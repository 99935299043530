import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { getNewApiClient } from "./client";
import {
  User,
  WhoAmIDocument,
  WhoAmIQuery,
} from "./__generated__/backend_gateway-types";

export class BgwService {
  private static instance: BgwService;
  private client: ApolloClient<NormalizedCacheObject>;
  private selectedOrganizationId: string | null;
  private user: User | null;

  private constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.selectedOrganizationId = localStorage.getItem(
      "selectedOrganizationId"
    );
    this.client = client;
    this.user = null;
  }

  public static getInstance(reset: boolean = false): BgwService {
    if (!BgwService.instance || reset) {
      BgwService.instance = new BgwService(getNewApiClient());
    }
    return BgwService.instance;
  }

  getClient(): ApolloClient<NormalizedCacheObject> {
    return this.client;
  }

  async reset(): Promise<void> {
    this.client.resetStore();
    await BgwService.getInstance(true);
  }

  async setWhoAmI(): Promise<void> {
    const result = await this.client.query({
      query: WhoAmIDocument,
    });
    const { error } = result;
    if (error) {
      console.error(error);
      return;
    }
    const { data }: { data: WhoAmIQuery } = result;
    if (data) {
      this.user = data.whoAmI || null;
    }
  }

  setSelectedOrganizationId = (selectedOrganizationId: string | null): void => {
    if (
      selectedOrganizationId &&
      this.selectedOrganizationId !== selectedOrganizationId
    ) {
      this.selectedOrganizationId = selectedOrganizationId;
      localStorage.setItem("selectedOrganizationId", selectedOrganizationId);
      this.client.resetStore();
    } else if (selectedOrganizationId === null) {
      localStorage.removeItem("selectedOrganizationId");
      this.client.resetStore();
    }
  };

  async whoAmI(): Promise<User | null> {
    if (this.user === null) {
      await this.setWhoAmI();
    }
    return this.user;
  }
}
