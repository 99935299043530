import { ReactNode } from "react";
import Box from "@mui/joy/Box";

import { FOOTER_HEIGHT, RIGHT_PLANE_WIDTH } from "../constants";
import { colors } from "../../colors";

export const Root = (props: {
  children?: ReactNode;
  drawerOpen: boolean;
  drawerWidth: number;
  sx?: any;
}) => {
  const { drawerOpen, drawerWidth, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={[
        {
          bgcolor: colors.grey1,
          display: "grid",
          pt: 0,
          px: 0,
          overflow: "hidden",
          gridTemplateColumns: {
            xs: "1fr",
            // sm: `${drawerWidth}px auto`,
            sm: `auto`,
            md: `0 auto ${RIGHT_PLANE_WIDTH}px`,
            lg: `${drawerWidth}px auto ${RIGHT_PLANE_WIDTH}px`,
          },
          gridTemplateRows: {
            xs: `auto ${FOOTER_HEIGHT}px`,
            sm: `auto`,
          },
          minHeight: "100vh",
          ...(drawerOpen && {
            height: "100vh",
            overflow: "hidden",
          }),
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
};
