import { useContext, useEffect, useMemo, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import {
  Deployment,
  useCreateMyApiStackMutation,
  useFindMyApiStackValuesQuery,
  useGetMyApiStackStatusQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { StackSummaryComponent } from "./stack_summary";

export const ApiDeployer = (props: {
  deploymentId: string;
  deployment: Deployment | null;
  deploymentEnabled: boolean;
  setStackCompleted: (status: boolean) => void;
}) => {
  const { deploymentId, deployment, deploymentEnabled, setStackCompleted } =
    props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: findMyApiStackValuesData,
    loading: findMyApiStackValuesLoading,
    refetch: findMyApiStackValuesRefetch,
  } = useFindMyApiStackValuesQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const stackId = useMemo(
    () => findMyApiStackValuesData?.findMyApiStackValues || null,
    [findMyApiStackValuesData]
  );

  const {
    data: getMyApiStackStatusData,
    loading: getMyApiStackStatusLoading,
    refetch: getMyApiStackStatusRefetch,
  } = useGetMyApiStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });
  const stackStatus = useMemo(
    () => getMyApiStackStatusData?.getMyApiStackStatus?.status,
    [getMyApiStackStatusData]
  );

  const [triggeringStack, setTriggeringStack] = useState<boolean>(false);

  const [createMyApiStack, { loading: createMyApiStackLoading }] =
    useCreateMyApiStackMutation({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
      onCompleted: () => {
        setTimeout(async () => {
          await getMyApiStackStatusRefetch();
          setTriggeringStack(false);
          findMyApiStackValuesRefetch();
        }, 3000);
      },
    });

  // Start deployment
  useEffect(() => {
    if (stackStatus === null && !triggeringStack && deploymentEnabled) {
      setTriggeringStack(true);
      createMyApiStack({});
    }
  }, [stackStatus, deploymentEnabled, createMyApiStack, triggeringStack]);

  // Check deployment progress
  useEffect(() => {
    const intervalId = setInterval(() => {
      if ((stackStatus || "").indexOf("IN_PROGRESS") > -1) {
        getMyApiStackStatusRefetch();
        findMyApiStackValuesRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [stackStatus, getMyApiStackStatusRefetch, findMyApiStackValuesRefetch]);

  // Callback
  useEffect(() => {
    setStackCompleted(
      ["UPDATE_COMPLETE", "CREATE_COMPLETE", "ROLLBACK_COMPLETE"].includes(
        stackStatus || ""
      )
    );
  }, [stackStatus, setStackCompleted]);

  const link = `https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/events?filteringText=&filteringStatus=active&viewNested=true&stackId=${stackId}`;

  return (
    <StackSummaryComponent
      loading={
        findMyApiStackValuesLoading ||
        getMyApiStackStatusLoading ||
        createMyApiStackLoading
      }
      deploymentEnabled={deploymentEnabled}
      title="API Gateway"
      link={link}
      status={stackStatus}
    />
  );
};
