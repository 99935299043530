import { useContext } from "react";
import { ProfileComponent } from "./component";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useWhoAmIQuery } from "../../services/backend_gateway/__generated__/backend_gateway-types";

export const ProfileContainer = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { bgwService } = useContext(BgwContext);
  const {
    data: whoAmIData,
    loading: whoAmILoading,
    error: whoAmIError,
  } = useWhoAmIQuery({
    client: bgwService.getClient(),
  });
  if (whoAmIError) {
    console.error("ProfileContainer: useWhoAmIQuery fetch error", whoAmIError);
  }

  return (
    <ProfileComponent
      loading={whoAmILoading}
      user={whoAmIData?.whoAmI || null}
      signOut={signOut}
    />
  );
};
