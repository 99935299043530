import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";

export const StackSummaryComponent = (props: {
  loading: boolean;
  deploymentEnabled: boolean;
  title: string;
  link: string | null;
  status: string | null | undefined;
}) => {
  const { loading, deploymentEnabled, title, link, status } = props;
  return (
    <>
      <Grid xs={4}>
        <Typography level="title-sm">{title}</Typography>
      </Grid>
      <Grid xs={4}>
        <Typography level="body-sm">{status || "..."}</Typography>
      </Grid>
      <Grid xs={2}>
        {loading && deploymentEnabled && <CircularProgress size="sm" />}
      </Grid>
      <Grid xs={2}>
        <Typography level="body-sm">
          {link && (
            <Link
              href={link}
              target="_blank"
              fontSize="xs"
              disabled={status === null || status === undefined}
            >
              AWS Console
            </Link>
          )}
        </Typography>
      </Grid>
    </>
  );
};
