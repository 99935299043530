import { useNavigate } from "react-router-dom";
import { DeploymentStepsComponent } from "./component";
import { StepperComponent } from "./stepper";
import { DEPLOYMENT_STEPS } from "./steps";

export const DeploymentStepsContainer = (props: {
  stepId: string;
  deploymentId: string;
}) => {
  const { stepId, deploymentId } = props;
  const stepDef = DEPLOYMENT_STEPS.find((s) => s.id === stepId);
  const stepIx = DEPLOYMENT_STEPS.findIndex((s) => s.id === stepId);

  const navigate = useNavigate();
  let navigateToPrevious: (() => void) | null = null;
  if (stepIx > 0) {
    navigateToPrevious = () =>
      navigate(
        `/deployment_steps/${deploymentId}/${DEPLOYMENT_STEPS[stepIx - 1].id}`,
        {
          replace: true,
        }
      );
  }
  let navigateToNext: (() => void) | null = null;
  if (stepIx < DEPLOYMENT_STEPS.length - 1) {
    navigateToNext = () =>
      navigate(
        `/deployment_steps/${deploymentId}/${DEPLOYMENT_STEPS[stepIx + 1].id}`,
        {
          replace: true,
        }
      );
  }
  const naviateToStep = (stepId: string) => {
    navigate(`/deployment_steps/${deploymentId}/${stepId}`, {
      replace: true,
    });
  };

  const StepComponent = stepDef!.component;
  return (
    <DeploymentStepsComponent
      deploymentId={deploymentId}
      stepComponent={
        <StepComponent
          deploymentId={deploymentId}
          navigateToNext={navigateToNext}
          navigateToPrevious={navigateToPrevious}
        />
      }
      stepperComponent={
        <StepperComponent stepId={stepId} naviateToStep={naviateToStep} />
      }
    />
  );
};
