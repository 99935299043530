import { useContext, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";
import { useGetMyDeploymentsQuery } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeployDeploymentStacksComponent } from "./component";
import { VpcDeployer } from "./vpc_deployer";
import { ApiDeployer } from "./api_deployer";
import { DeploymentDeployer } from "./deployment_deployer";
import { AlertsDeployer } from "./alerts_deployer";
import { HostedZoneDeployer } from "./hosted_zone_deployer";
import { NsRecordDeployer } from "./ns_record_deployer";
import { CertDeployer } from "./cert_deployer";

export const DeployDeploymentStacksContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
  navigateWhenFinished: () => void;
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const [deploymentEnabled, setDeploymentEnabled] = useState<boolean>(false);
  const setDeploymentEnabledTrue = () => setDeploymentEnabled(true);

  const { data: getMyDeploymentsData, loading: getMyDeploymentsLoading } =
    useGetMyDeploymentsQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  const [vpcStackCompleted, setVpcStackCompleted] = useState<boolean>(true);
  const [apiStackCompleted, setApiStackCompleted] = useState<boolean>(true);
  const [deploymentStackCompleted, setDeploymentStackCompleted] =
    useState<boolean>(true);
  const [alertsStackCompleted, setAlertsStackCompleted] =
    useState<boolean>(true);
  const [hostedZoneCompleted, sethostedZoneCompleted] = useState<boolean>(true);
  const [nsRecordCompleted, setNsRecordCompleted] = useState<boolean>(true);
  const [certCompleted, setCertCompleted] = useState<boolean>(true);

  const percentDone =
    (((vpcStackCompleted ? 1 : 0) +
      (apiStackCompleted ? 1 : 0) +
      (deploymentStackCompleted ? 1 : 0) +
      (alertsStackCompleted ? 1 : 0) +
      (hostedZoneCompleted ? 1 : 0) +
      (nsRecordCompleted ? 1 : 0) +
      (certCompleted ? 1 : 0)) /
      7) *
    100;

  return (
    <DeployDeploymentStacksComponent
      loading={getMyDeploymentsLoading}
      deployment={deployment || null}
      vpcDeployer={
        <VpcDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={deploymentEnabled}
          setStackCompleted={(status: boolean) => setVpcStackCompleted(status)}
        />
      }
      apiDeployer={
        <ApiDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={deploymentEnabled}
          setStackCompleted={(status: boolean) => setApiStackCompleted(status)}
        />
      }
      deploymentDeployer={
        <DeploymentDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={deploymentEnabled}
          setStackCompleted={(status: boolean) =>
            setDeploymentStackCompleted(status)
          }
        />
      }
      alertsDeployer={
        <AlertsDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={deploymentEnabled}
          setStackCompleted={(status: boolean) =>
            setAlertsStackCompleted(status)
          }
        />
      }
      hostedZoneDeployer={
        <HostedZoneDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={deploymentEnabled}
          setStackCompleted={(status: boolean) =>
            sethostedZoneCompleted(status)
          }
        />
      }
      nsRecordDeployer={
        <NsRecordDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={hostedZoneCompleted}
          setStackCompleted={(status: boolean) => setNsRecordCompleted(status)}
        />
      }
      certDeployer={
        <CertDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={nsRecordCompleted}
          setStackCompleted={(status: boolean) => setCertCompleted(status)}
        />
      }
      percentDone={percentDone}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      deploymentEnabled={deploymentEnabled}
      setDeploymentEnabled={setDeploymentEnabledTrue}
      deploymentCompleted={
        vpcStackCompleted &&
        apiStackCompleted &&
        deploymentStackCompleted &&
        alertsStackCompleted &&
        hostedZoneCompleted &&
        nsRecordCompleted &&
        certCompleted
      }
    />
  );
};
