export const colors = {
  white: "FFFFFF",

  grey1: "#FBFCFD",
  grey2: "#F7F7F7",
  grey3: "#EEEEEE",
  grey4: "#D8D8D8",
  grey5: "#CCCCCC",
  grey6: "#BBBBBB",
  grey7: "#AAAAAA",
  grey8: "#999999",
  grey9: "#666666",
  grey10: "#474747",
  grey11: "#333333",
  grey12: "#222222",

  shadow: "rgba(0,0,0,0.6)",
};
