import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import {
  Deployment,
  useCreateMyFullDomainNameHostedZoneMutation,
  useGetMyFullDomainNameHostedZonesQuery,
  useGetMyFullDomainNameHostedZoneStatusLazyQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { StackSummaryComponent } from "./stack_summary";

export const HostedZoneDeployer = (props: {
  deploymentId: string;
  deployment: Deployment | null;
  deploymentEnabled: boolean;
  setStackCompleted: (status: boolean) => void;
}) => {
  const { deploymentId, deployment, deploymentEnabled, setStackCompleted } =
    props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const [changeId, setChangeId] = useState<string | null>(null);
  const [changeStatus, setChangeStatus] = useState<string | null>(null);

  const [
    getMyFullDomainNameHostedZoneStatus,
    {
      data: getMyFullDomainNameHostedZoneStatusData,
      loading: getMyFullDomainNameHostedZoneStatusLoading,
    },
  ] = useGetMyFullDomainNameHostedZoneStatusLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const hostedZoneStatus =
    getMyFullDomainNameHostedZoneStatusData?.getMyFullDomainNameHostedZoneStatus;

  const {
    data: getMyFullDomainNameHostedZonesData,
    loading: getMyFullDomainNameHostedZonesLoading,
    refetch: getMyFullDomainNameHostedZonesRefetch,
  } = useGetMyFullDomainNameHostedZonesQuery({
    variables: {
      deploymentId,
    },
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const hostedZones =
    getMyFullDomainNameHostedZonesData?.getMyFullDomainNameHostedZones;

  const hostedZoneId =
    (hostedZones?.length || 0) > 0 ? hostedZones![0].hostedZoneId : null;

  const [triggeringStack, setTriggeringStack] = useState<boolean>(false);

  const [
    createMyFullDomainNameHostedZone,
    { loading: createMyFullDomainNameHostedZoneLoading },
  ] = useCreateMyFullDomainNameHostedZoneMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
    onCompleted: (data) => {
      setChangeId(data?.createMyFullDomainNameHostedZone || null);
      setChangeStatus("CREATING");
      setTimeout(async () => {
        await getMyFullDomainNameHostedZonesRefetch();
        setTriggeringStack(false);
        if (changeId) {
          getMyFullDomainNameHostedZoneStatus({
            variables: {
              deploymentId,
              changeInfoId: changeId,
            },
          });
        }
      }, 3000);
    },
  });

  // Start deployment
  useEffect(() => {
    if (
      (hostedZones === null || hostedZones?.length === 0) &&
      !triggeringStack &&
      deploymentEnabled
    ) {
      setTriggeringStack(true);
      createMyFullDomainNameHostedZone();
    }
  }, [
    hostedZones,
    deploymentEnabled,
    createMyFullDomainNameHostedZone,
    triggeringStack,
  ]);

  // Check deployment progress
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (hostedZones === null || hostedZones?.length === 0) {
        getMyFullDomainNameHostedZonesRefetch();
        if (changeId) {
          getMyFullDomainNameHostedZoneStatus({
            variables: {
              deploymentId,
              changeInfoId: changeId,
            },
          });
        }
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    hostedZones,
    deploymentId,
    getMyFullDomainNameHostedZonesRefetch,
    getMyFullDomainNameHostedZoneStatus,
    changeId,
  ]);

  // Callback
  useEffect(() => {
    setStackCompleted(
      hostedZoneStatus ? hostedZoneStatus === "DONE" : !!hostedZoneId
    );
  }, [hostedZoneStatus, hostedZoneId, setStackCompleted]);

  const link = `https://${deployment?.awsRegion}.console.aws.amazon.com/route53/v2/hostedzones?region=${deployment?.awsRegion}#ListRecordSets/${hostedZoneId}`;

  return (
    <StackSummaryComponent
      loading={
        getMyFullDomainNameHostedZoneStatusLoading ||
        getMyFullDomainNameHostedZonesLoading ||
        createMyFullDomainNameHostedZoneLoading
      }
      deploymentEnabled={deploymentEnabled}
      title="Domain Name Registration"
      link={link}
      status={hostedZoneId ? "REGISTRATION_COMPLETE" : changeStatus}
    />
  );
};
