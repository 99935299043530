import { useNavigate, useParams } from "react-router-dom";
import { OnboardingContainer } from "./container";
import { ONBOARDING_STEPS } from "./steps";
import { useEffect } from "react";
import { AddNewDeploymentContainer } from "./add_new_deployment/container";

export const OnboardingPage = () => {
  const params = useParams();
  const { deploymentId, stepId } = params;

  const navigate = useNavigate();
  const validStep = ONBOARDING_STEPS.map((s) => s.id).includes(stepId || "");
  useEffect(() => {
    if (deploymentId && !validStep) {
      navigate(`/onboarding/${deploymentId}/${ONBOARDING_STEPS[0].id}`, {
        replace: true,
      });
    }
  }, [validStep, deploymentId, navigate]);
  if (deploymentId === undefined) {
    return (
      <AddNewDeploymentContainer
        navigateToNext={(id: string) => {
          navigate(`/onboarding/${id}/${ONBOARDING_STEPS[0].id}`, {
            replace: true,
          });
        }}
      />
    );
  }
  if (!validStep) {
    return null;
  }
  return <OnboardingContainer deploymentId={deploymentId} stepId={stepId!} />;
};
