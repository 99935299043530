import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import LinearProgress from "@mui/joy/LinearProgress";
import { Organization } from "../../../../services/backend_gateway/__generated__/backend_gateway-types";

export const OrganizationSelectorComponent = (props: {
  navigateToRoot: () => void;
  ograniztions: Organization[];
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (customerId: string | null) => void;
  role: string | null;
  loading: boolean;
}) => {
  const {
    role,
    selectedOrganizationId,
    ograniztions,
    setSelectedOrganizationId,
    navigateToRoot,
    loading,
  } = props;

  if (role === null || !["ROOT"].includes(role!)) {
    return null;
  }

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Select
      placeholder="Select customer"
      value={selectedOrganizationId}
      slotProps={{
        listbox: {
          sx: {
            maxHeight: 300,
            overflow: "auto", // required for scrolling
            zIndex: 1201, // Because of the floating drawer on mobile
          },
        },
      }}
    >
      <Option
        value="NO CUSTOMER SELECTED"
        key="NO CUSTOMER SELECTED"
        onClick={() => {
          setSelectedOrganizationId(null);
          navigateToRoot();
        }}
      >
        Clear selection
      </Option>
      {ograniztions?.map((ograniztion) => (
        <Option
          value={ograniztion.id}
          key={ograniztion.id}
          onClick={() => {
            setSelectedOrganizationId(ograniztion.id);
            navigateToRoot();
          }}
        >
          {ograniztion.name || ograniztion.id}
        </Option>
      ))}
    </Select>
  );
};
