import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import { ReactNode } from "react";
import { LogoText } from "../../layouts/drawer_layout/drawer/logo_text";

export const LeanLayout = (props: { children?: ReactNode }) => {
  const { children } = props;
  return (
    <Box
      sx={{
        pt: 0,
        px: 0,
        overflow: "hidden",
        display: "grid",
        gridTemplateRows: "auto 120px",
        gridTemplateColumns: {
          xs: "auto",
          sm: "64px auto 64px",
          md: "1fr 640px 1fr",
        },
      }}
    >
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          mt: 4,
          gridRow: 1,
          gridColumn: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        {children}
      </Sheet>
      <Box
        sx={{
          py: 4,
          gridRow: 2,
          gridColumn: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        <LogoText />
      </Box>
    </Box>
  );
};
